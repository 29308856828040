<template>
  <div class="flex-col page">
    <div class="flex-col">
      <div class="flex-col section">
        <div class="flex-row justify-between items-center section_2">
          <div class="flex-row">
            <img class="self-center image" src="../../assets/6f7ef0b09187f296c1dc6a4af0c3d406.png" />
            <span class="self-start text ml-18">数澄保</span>
          </div>
          <div class="flex-col justify-center items-center text-wrapper" style="cursor: pointer" @click="openVerify()"><span class="text_2">证据查验</span></div>
        </div>
        <div class="flex-col group">
          <div class="flex-row items-center self-stretch">
            <a class="font text_3 cursor-point" href="/">首页</a>
            <img class="image_2 ml-3" src="../../assets/5ede9e4ecda5b3f787cbbb15e4ad1974.png" />
            <span class="font text_4 ml-3">数据权益风险提示</span>
          </div>
          <span class="self-start text_5 mt-14">数据权益风险提示</span>
        </div>
      </div>
      <div class="flex-col justify-start section_3">
        <div class="flex-row equal-division">
          <div class="flex-col section_4 section_19">
            <img class="self-stretch image_3" src="../../assets/453227d7c847c0ae745ec6a2025dd8c0.png" />
            <span class="self-start font_1 mt-23">海南自由贸易港知识产权法院</span>
            <span class="self-stretch font_3 text_7 mt-23">
              2020年12月31日，海南自由贸易港知识产权法院正式揭牌办公。海南成为继北京、上海、广东之后，全国第四个拥有专门知识产权法院的省份。
            </span>
          </div>
          <div class="flex-col section_4 section_18 ml-23">
            <img class="self-stretch image_3" src="../../assets/e91cc2fd0ca3523a72d654bfe0ce04e8.png" />
            <span class="self-start font_1 mt-23">崖州湾科技城知产特区审判庭</span>
            <span class="self-stretch font_3 text_8 mt-23">
              2021年10月29日，海南自由贸易港知识产权法院三亚崖州湾科技城知识产权特区审判庭、海南第二涉外民商事法庭三亚崖州湾科技城派出审判庭（以下简称“两庭”）揭牌成立，标志着三亚知识产权保护、涉外民商事司法保障能力迈上了新的台阶。
            </span>
          </div>
          <div class="flex-col section_4 section_1 ml-23">
            <img class="self-stretch image_3 image_1" src="../../assets/7cc9c50ab0a0a011bd6d83924adf4b36.png" />
            <span class="self-start font_1 mt-23">琼山法院</span>
            <span class="self-stretch font_3 text_9 mt-23">
              海口市琼山区人民法院由原琼山市人民法院撤销后于2003年1月成立，内设机构有：立案庭、刑事审判庭、民事审判庭、行政审判庭、审判管理办公室、执行局、环境资源审判庭、政治部、办公室、司法警察大队等10个部门和2个派出法庭（云龙人民法庭和三门坡人民法庭），形成较为完善的审判及管理系统。
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-row equal-division_4 mt-68">
      <div class="flex-col equal-division-item_2 section_6">
        <div class="flex-col self-start group_2">
          <span class="font_5 text_11">《关于审理涉电子商务平台知识产权纠纷案件的指导意见》（2023年）​</span>
          <span class="font_3 text_14">明确电商平台不得擅自使用用户数据生成商业竞争分析报告。</span>
        </div>
        <div class="flex-col items-start self-stretch group_5">
          <span class="font_5 text_16">《关于审理数据权益纠纷案件适用法律若干问题的规定》（征求意见稿）​</span>
          <span class="font_3 text_19">首次提出“数据生产者权”，保护企业对合法收集数据的控制权。</span>
        </div>
        <div class="flex-row items-center section_5 pos">
          <img class="image_5" src="../../assets/51243ba9a37d9048a1ae41b879c80dc6.png" />
          <span class="font_4 text_10 ml-8">最高人民法院司法解释</span>
        </div>
      </div>
      <div class="flex-row equal-division-item_2 section_7 ml-81">
        <div class="flex-col justify-start items-start self-center section_8">
          <div class="flex-col justify-start group_4">
            <div class="flex-col justify-start items-center text-wrapper_2">
              <span class="text_15">数据权益典型司法案例</span>
            </div>
          </div>
        </div>
        <div class="flex-col flex-1 self-start group_3">
          <span class="self-start font_2 text_12">“某公司数据爬虫案”​​（2022年）</span>
          <span class="self-stretch font_3 text_13">法院认定未经授权爬取裁判文书数据构成不正当竞争，判赔500万元。</span>
          <div class="self-stretch divider"></div>
          <span class="self-start font_2 text_17">“人脸识别第一案”​​（2021年）</span>
          <span class="self-stretch font_3 text_18">
            杭州法院判决动物园删除游客人脸信息，确立“人脸数据非必要不采集”原则。
          </span>
        </div>
        <img class="image_4 pos_2" src="../../assets/1be0c5be29e2f8735fe6dd0ea8086942.png" />
      </div>
    </div>
    <div class="flex-col mt-68">
      <div class="flex-col">
        <div class="flex-col section_9">
          <span class="self-center font_6">行业性管理办法</span>
          <div class="flex-row equal-division_2 mt-83">
            <div class="flex-col equal-division-item_3">
              <span class="font_7">《数据要素市场化配置改革总体方案》（2022年）</span>
              <div class="flex-col group_1 mt-31">
                <span class="self-start font_1">​核心内容</span>
                <span class="self-stretch font_3 text_1">
                  推动数据产权分置改革（所有权、使用权、收益权分离）；
                  <br />
                </span>
                <span class="self-start font_3">建立数据交易场所和定价机制；</span>
              </div>
            </div>
            <div class="horiz-divider ml-43"></div>
            <div class="flex-col equal-division-item_1 ml-43">
              <span class="self-start font_7">​​《互联网数据安全管理规定》（2021年）​</span>
              <div class="flex-col items-start self-stretch mt-31">
                <span class="font_1">​关键要求</span>
                <span class="font_3 text_20">网络平台需对用户数据实行分类分级管理；</span>
                <span class="font_3">禁止利用数据分析进行“大数据杀熟”；</span>
              </div>
            </div>
            <div class="horiz-divider ml-43"></div>
            <div class="flex-col equal-division-item_5 ml-43">
              <span class="self-start font_7">《生成式人工智能管理办法》（2023年施行）​</span>
              <div class="flex-col self-stretch mt-31">
                <span class="self-start font_1">AI数据风险提示重点</span>
                <span class="self-start font_3 text_21">禁止使用未经授权的司法数据训练AI模型；</span>
                <span class="self-stretch font_3 text_22">生成的内容需标注“AI生成”，不得替代法律咨询；</span>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-col section_10">
          <span class="self-center font_6 text_23">相关法律法规</span>
          <div class="flex-row equal-division_2 mt-75">
            <div class="flex-col equal-division-item_4 group_6">
              <span class="font_8 text_24">《中华人民共和国个人信息保护法》（2021年施行）​</span>
              <div class="flex-col group_9 mt-31">
                <span class="self-start font_9">关键要求</span>
                <span class="self-stretch font_10 text_30">
                  明确个人信息的定义（姓名、身份证号、生物识别信息等）；
                  <br />
                </span>
                <span class="self-stretch font_10 text_33">
                  规范企业收集、存储、使用个人信息的“最小必要原则”；
                  <br />
                </span>
                <span class="self-start font_10">违法处理个人信息最高可处5%营业额罚款；</span>
              </div>
            </div>
            <div class="section_15 horiz-divider_2 ml-32"></div>
            <div class="flex-col equal-division-item_4 group_7 ml-32">
              <span class="font_9 text_26">《中华人民共和国数据安全法》（2021年施行）​</span>
              <div class="flex-col mt-67">
                <span class="self-start font_9">​关键要求</span>
                <span class="self-stretch font_10 text_31">
                  数据分类分级制度（核心数据、重要数据、一般数据）；
                  <br />
                </span>
                <span class="self-stretch font_10 text_34">
                  跨境数据流动需申报安全评估（如《数据出境安全评估办法》）；
                  <br />
                </span>
                <span class="self-stretch font_10">企业需建立数据安全管理制度并接受监管检查。</span>
              </div>
            </div>
            <div class="section_15 horiz-divider_2 ml-32"></div>
            <div class="flex-col equal-division-item_4 group_8 ml-32">
              <span class="font_8 text_25">《中华人民共和国网络安全法》（2017年施行，2021年修订）</span>
              <div class="flex-col group_10 mt-31">
                <span class="self-start font_9">​关键要求</span>
                <span class="self-stretch font_10 text_32 mt-8">
                  网络运营者需保障用户数据安全；发生数据泄露事件需在1小时内向主管部门报告。
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-col group_11 mt-68">
        <span class="self-center font_6">知识产权风险防控指导</span>
        <div class="flex-col self-stretch mt-93">
          <div class="flex-row">
            <div class="flex-col justify-start items-center text-wrapper_1">
              <span class="font_6 text_35">更新中…</span>
            </div>
            <div class="flex-col justify-start items-center text-wrapper_1 ml-23">
              <span class="font_6 text_35">更新中…</span>
            </div>
            <div class="flex-col justify-start items-center text-wrapper_1 ml-23">
              <span class="font_6 text_35">更新中…</span>
            </div>
          </div>
          <div class="flex-row mt-23">
            <div class="flex-col justify-start items-center text-wrapper_3">
              <span class="font_6 text_35">更新中…</span>
            </div>
            <div class="flex-col justify-start items-center text-wrapper_3 ml-23">
              <span class="font_6 text_35">更新中…</span>
            </div>
            <div class="flex-col justify-start items-center text-wrapper_3 ml-23">
              <span class="font_6 text_35">更新中…</span>
            </div>
          </div>
        </div>
      </div>
      <common-bottom class="section_12 mt-68"/>
    </div>
  </div>
</template>

<script>
  import CommonBottom from "@/component/common-bottom.vue";

  export default {
    components: {CommonBottom},
    props: {},
    data() {
      return {};
    },

    methods: {
      openVerify(){
        window.open('https://czt.jiulaw.cn/external-verify')
      },
    },
  };
</script>

<style scoped lang="css">
  .ml-3 {
    margin-left: 0.2196vw;
  }
  .ml-81 {
    margin-left: 5.9297vw;
  }
  .mt-83 {
    margin-top: 6.0761vw;
  }
  .ml-43 {
    margin-left: 3.1479vw;
  }
  .mt-75 {
    margin-top: 5.4905vw;
  }
  .mt-67 {
    margin-top: 4.9048vw;
  }
  .mt-31 {
    margin-top: 2.2694vw;
  }
  .mt-93 {
    margin-top: 6.8082vw;
  }
  .ml-23 {
    margin-left: 1.6837vw;
  }
  .mt-23 {
    margin-top: 1.6837vw;
  }
  .ml-21 {
    margin-left: 1.5373vw;
  }
  .page {
    background-color: #ffffff;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
  .section {
    padding-bottom: 0.9861vw;
    background-image: url('../../assets/327d772e0541620fa06f2a7de8b9a131.png');
    background-position: 0% 0%;
    background-size: 100vw 19.6845vw;
    background-repeat: no-repeat;
  }
  .section_2 {
    padding: 0.4736vw 6.2496vw 0.6252vw;
    background-color: #fefeff29;
  }
  .image {
    width: 2.0498vw;
    height: 2.0498vw;
  }
  .text {
    color: #ffffff;
    font-size: 1.6669vw;
    font-family: 阿里妈妈数黑体;
    font-weight: 700;
  }
  .text-wrapper {
    background-image: linear-gradient(285deg, #f45a34 -36.1%, #ff9e36 99.4%);
    border-radius: 0.4165vw;
    width: 6.6669vw;
    height: 2.4993vw;
  }
  .text_2 {
    color: #ffffff;
    font-size: 1.0417vw;
    font-family: Microsoft YaHei UI;
  }
  .group {
    padding: 4.9993vw 6.2496vw 5vw 6.2504vw;
  }
  .font {
    font-size: 0.8331vw;
    font-family: Microsoft YaHei UI;
  }
  .text_3 {
    color: #ffffff;
  }
  .image_2 {
    width: 1.0249vw;
    height: 1.0249vw;
  }
  .text_4 {
    color: #ffffffa6;
  }
  .text_5 {
    color: #ffffff;
    font-size: 2.8126vw;
    font-family: Microsoft YaHei UI;
    font-weight: 700;
  }
  .section_3 {
    padding: 4.9993vw 0;
    background-color: #e3e9f24d;
  }
  .equal-division {
    margin-left: 6.1977vw;
    margin-right: 6.3023vw;
  }
  .section_4 {
    flex: 1 1 28.0205vw;
  }
  .section_19 {
    padding: 1.6669vw 1.6098vw 8.56vw 1.6669vw;
    background-color: #fefeff;
    border-radius: 0.4165vw;
    height: 39.9414vw;
  }
  .image_3 {
    width: 24.6706vw;
    height: 16.3982vw;
  }
  .font_1 {
    font-size: 1.1457vw;
    font-family: Microsoft YaHei UI;
    font-weight: 700;
    color: #172b4d;
  }
  .font_3 {
    font-size: 1.1457vw;
    font-family: Microsoft YaHei UI;
    line-height: 2.1874vw;
    color: #172b4d;
  }
  .text_7 {
    text-align: justify;
  }
  .section_18 {
    padding: 1.6669vw 1.6105vw 4.1801vw 1.6662vw;
    background-color: #fefeff;
    border-radius: 0.4165vw;
    height: 39.9414vw;
  }
  .text_8 {
    text-align: justify;
  }
  .section_1 {
    padding: 1.6669vw 1.0242vw 1.9824vw 1.6669vw;
    background-color: #fefeff;
    border-radius: 0.4165vw;
    height: 39.9414vw;
  }
  .image_1 {
    margin-right: 0.6596vw;
  }
  .text_9 {
    text-align: justify;
  }
  .equal-division_4 {
    margin: 0 6.2504vw;
  }
  .equal-division-item_2 {
    position: relative;
    flex: 1 1 40.7811vw;
  }
  .section_6 {
    padding: 1.7423vw 1.459vw 3.6288vw 1.5622vw;
    background-image: linear-gradient(123.3deg, #a8c8ff33 6.4%, #a8c8ff00 100%);
    border-radius: 0.2086vw 4.1669vw 0vw 4.1669vw;
    box-shadow: -0.104vw 0.104vw 0.3646vw #6886b61a;
    height: 28.5893vw;
    border: solid 0.05124vw #0147be;
  }
  .group_2 {
    padding: 2.2233vw 0;
    width: 30.91vw;
    border-bottom: solid 0.104vw #172b4d1a;
  }
  .font_5 {
    font-size: 1.1457vw;
    font-family: Microsoft YaHei UI;
    line-height: 2.1874vw;
    font-weight: 700;
    color: #172b4d;
  }
  .text_11 {
    text-align: justify;
  }
  .text_14 {
    text-align: justify;
  }
  .group_5 {
    padding-top: 1.2584vw;
  }
  .text_16 {
    text-align: justify;
    width: 30.8931vw;
  }
  .text_19 {
    text-align: justify;
    width: 30.3075vw;
  }
  .section_5 {
    padding: 0.5205vw 2.0835vw 0.787vw;
    background-image: linear-gradient(90deg, #0147be 19.7%, #0147be00 96.5%);
    border-radius: 0.2086vw 0vw 0vw 0vw;
  }
  .pos {
    position: absolute;
    left: 0;
    top: 0;
  }
  .image_5 {
    width: 0.8053vw;
    height: 1.2445vw;
  }
  .font_4 {
    font-size: 1.1457vw;
    font-family: 微软雅黑;
    font-weight: 700;
    color: #ffffff;
  }
  .text_10 {
    margin-right: 6.7467vw;
  }
  .section_7 {
    padding: 4.0102vw 0 3.1259vw;
    background-image: linear-gradient(236.7deg, #a8c8ff33 6.4%, #a8c8ff00 100%);
    border-radius: 0.2086vw;
    box-shadow: -0.104vw 0.104vw 0.3646vw #6886b61a;
    height: 28.5893vw;
    border: solid 0.05124vw #0147be;
  }
  .section_8 {
    margin-left: -13.7496vw;
    padding-top: 1.1977vw;
    border-radius: 50%;
    filter: drop-shadow(0.2086vw 0.2086vw 0.7811vw #172b4d3b);
    background-image: url('../../assets/8aedebd63ea9a55e32cc4a244c66a17c.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 21.3543vw;
    height: 21.3507vw;
    border: solid 4.1654vw #fefeff;
  }
  .group_4 {
    margin-left: 1.1977vw;
    padding: 1.1457vw 0 1.1493vw;
    border-radius: 50%;
    width: 18.9583vw;
    height: 18.9553vw;
    border: solid 0.1025vw #dae8ff;
  }
  .text-wrapper_2 {
    margin-left: -3.0015vw;
    margin-top: -3.9898vw;
    padding: 4.0644vw 0;
    background-color: #d9e7ff;
    border-radius: 50%;
    width: 16.4583vw;
    height: 16.4583vw;
  }
  .text_15 {
    color: #172b4d;
    font-size: 2.3vw;
    font-family: Microsoft YaHei UI;
    line-height: 3.7496vw;
    text-align: center;
    width: 12.4451vw;
  }
  .group_3 {
    margin: 0.4678vw 1.6676vw 0 1.1977vw;
  }
  .font_2 {
    font-size: 1.1457vw;
    font-family: Microsoft YaHei UI;
    line-height: 1.1552vw;
    font-weight: 700;
    color: #172b4d;
  }
  .text_12 {
    margin-left: 0.1288vw;
    line-height: 1.153vw;
  }
  .text_13 {
    margin: 1.1464vw 0.5044vw 0 0.1288vw;
    text-align: justify;
  }
  .divider {
    margin-top: 3.7906vw;
    background-color: #172b4d1a;
    height: 0.104vw;
  }
  .text_17 {
    margin-left: 0.1288vw;
    margin-top: 1.6662vw;
    line-height: 1.153vw;
  }
  .text_18 {
    margin: 1.1471vw 0.284vw 0 0.1288vw;
    text-align: justify;
  }
  .image_4 {
    width: 23.8653vw;
    height: 2.9283vw;
  }
  .pos_2 {
    position: absolute;
    right: 0;
    top: 0;
  }
  .section_9 {
    padding: 4.9993vw 5.9202vw 4.9993vw 6.2504vw;
    background-image: url('../../assets/4c7d45bf115d81edd8081cd023d5df09.png');
    background-position: 0% 0%;
    background-size: 100vw 30.9327vw;
    background-repeat: no-repeat;
  }
  .font_6 {
    font-size: 2.8126vw;
    font-family: Microsoft YaHei UI;
    color: #172b4d;
  }
  .equal-division_2 {
    align-self: stretch;
  }
  .equal-division-item_3 {
    padding-bottom: 0.5652vw;
    width: 26.2079vw;
  }
  .font_7 {
    font-size: 1.1457vw;
    font-family: Microsoft YaHei UI;
    line-height: 1.1552vw;
    font-weight: 700;
    color: #0147be;
  }
  .group_1 {
    margin-right: 0.1076vw;
  }
  .text_1 {
    margin-top: 0.5593vw;
    text-align: justify;
  }
  .horiz-divider {
    background-color: #172b4d33;
    width: 0.05198vw;
    height: 12.1852vw;
  }
  .equal-division-item_1 {
    padding-bottom: 2.7511vw;
  }
  .text_20 {
    margin-top: 0.5586vw;
  }
  .equal-division-item_5 {
    padding-bottom: 2.754vw;
    width: 26.4231vw;
  }
  .text_21 {
    margin-top: 0.5512vw;
  }
  .text_22 {
    text-align: justify;
  }
  .section_10 {
    padding: 4.9993vw 5.4685vw 5.3119vw 6.2504vw;
    background-image: url('../../assets/76795f0fa359ace80878ec85c592c6cb.png');
    background-position: 0% 0%;
    background-size: 100vw 37.9107vw;
    background-repeat: no-repeat;
  }
  .text_23 {
    color: #ffffff;
  }
  .equal-division-item_4 {
    flex: 1 1 26.1347vw;
  }
  .group_6 {
    padding-bottom: 0.235vw;
  }
  .font_8 {
    font-size: 1.1457vw;
    font-family: Microsoft YaHei UI;
    line-height: 2.1874vw;
    font-weight: 700;
    color: #ffffff;
  }
  .text_24 {
    text-align: justify;
  }
  .group_9 {
    margin-right: 0.5293vw;
  }
  .font_9 {
    font-size: 1.1457vw;
    font-family: Microsoft YaHei UI;
    font-weight: 700;
    color: #ffffff;
  }
  .font_10 {
    font-size: 1.1457vw;
    font-family: Microsoft YaHei UI;
    line-height: 2.1874vw;
    color: #ffffff;
  }
  .text_30 {
    margin-top: 0.5586vw;
    text-align: justify;
  }
  .text_33 {
    text-align: justify;
  }
  .section_15 {
    margin-top: 1.0337vw;
  }
  .horiz-divider_2 {
    background-color: #fefeff33;
    width: 0.05198vw;
    height: 18.3302vw;
  }
  .group_7 {
    padding: 0.5124vw 0 0.2284vw;
  }
  .text_26 {
    line-height: 1.1574vw;
  }
  .text_31 {
    margin-top: 0.5586vw;
    text-align: justify;
  }
  .text_34 {
    text-align: justify;
  }
  .group_8 {
    padding-bottom: 6.8089vw;
  }
  .text_25 {
    text-align: justify;
  }
  .group_10 {
    margin-right: 0.2928vw;
  }
  .text_32 {
    text-align: justify;
  }
  .group_11 {
    padding: 0 6.2504vw;
  }
  .text-wrapper_1 {
    flex: 1 1 28.0205vw;
    padding: 6.3529vw 0 7.3097vw;
    background-color: #e3e9f24d;
    border-radius: 0.4165vw;
    height: 16.4034vw;
  }
  .text_35 {
    opacity: 0.39;
  }
  .text-wrapper_3 {
    flex: 1 1 28.0205vw;
    padding: 6.3536vw 0 7.3089vw;
    background-color: #e3e9f24d;
    border-radius: 0.4165vw;
    height: 16.4034vw;
  }
  .section_12 {
    padding: 0 0.9195vw;
    background-color: #172b4d;
  }
  .group_12 {
    margin-right: 5.2013vw;
    padding: 1.6662vw 0 1.6669vw;
  }
  .group_13 {
    margin-top: 0.2086vw;
    padding: 0 5.3302vw;
  }
  .font_11 {
    font-size: 1.1457vw;
    font-family: 微软雅黑;
    color: #ffffff;
  }
  .text_36 {
    opacity: 0.65;
  }
  .section_13 {
    background-color: #ffffff40;
    width: 0.05198vw;
    height: 4.9993vw;
  }
  .group_14 {
    margin-left: 5.4165vw;
    margin-top: 0.2086vw;
  }
  .text_43 {
    margin-left: 3.3258vw;
    margin-top: 3.0205vw;
  }
  .text_44 {
    margin-left: 3.3294vw;
    margin-top: 3.0205vw;
  }
  .text_45 {
    margin-left: 3.3104vw;
    margin-top: 3.0205vw;
  }
  .group_15 {
    margin-left: 5.3309vw;
    margin-right: 5.1999vw;
    padding: 0.8331vw 0 0.7635vw;
    border-top: solid 0.05198vw #ffffff40;
  }
  .image_6 {
    width: 2.1962vw;
    height: 1.6837vw;
  }
</style>
