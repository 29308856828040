<template>
  <div class="flex-col page">
    <div class="flex-col section">
      <div class="flex-row justify-between items-center section_2">
        <div class="flex-row">
          <img class="self-center image" src="../../assets/6f7ef0b09187f296c1dc6a4af0c3d406.png"/>
          <span class="self-start text ml-18">数澄保</span>
        </div>
        <div class="flex-col justify-center items-center text-wrapper" style="cursor: pointer" @click="openVerify()">
          <span class="text_2">证据查验</span></div>
      </div>
      <div class="flex-col group">
        <div class="flex-row items-center self-stretch">
          <a class="font text_3 cursor-point" href="/">首页</a>
          <img class="image_2 ml-3" src="../../assets/5ede9e4ecda5b3f787cbbb15e4ad1974.png"/>
          <span class="font text_4 ml-3">数字跨境争议解决服务</span>
        </div>
        <span class="self-start text_5 mt-14">数字跨境争议解决服务</span>
      </div>
    </div>
    <div class="flex-col group_2 mt-68">
      <div class="flex-row items-center self-start group_3">
        <img class="image_3" src="../../assets/8f09301e14b7b65739b1ceefa9577f2d.png"/>
        <div class="flex-1 group_4 ml-16">
          <span class="text_8">
            海口国际商事调解中心简介
            <br/>
          </span>
          <span class="font_6 text_6 text_7">Introduction of Haikou International Commercial Mediation Center</span>
        </div>
      </div>
      <span class="self-stretch font_6 text_6 text_9 mt-43">
        In order to implement the Overall Plan for Building Hainan Free Trade Port, establish a diversified mechanism
        for resolving commercial disputes, on June 29th, 2020, Haikou Municipal Government approved the Implementation
        Plan on Promoting the Establishment of Haikou International
      </span>
      <div class="flex-row equal-division_1 mt-43">
        <div class="flex-row justify-center items-center equal-division-item section_3 cursor-point"
             @click="openNewWindow('https://mp.weixin.qq.com/s/Yr_LX_WPa5w0RRrExePd1w')">
<!--          <img class="image_4" src="../../assets/8c8ea465f174bc96d5edf554639f71a1.png"/>-->
          <a class="self-end font_6 ml-17 cursor-point"
             href="https://mp.weixin.qq.com/s/Yr_LX_WPa5w0RRrExePd1w"
             target="_blank">Mediation Rules</a>
        </div>
        <div class="flex-row justify-center items-center equal-division-item section_4 ml-17 cursor-point"
             @click="openNewWindow('https://mp.weixin.qq.com/s/EB6Cwi1fsiSU4_rLPRjzrg')">
<!--          <img class="image_5" src="../../assets/8c8ea465f174bc96d5edf554639f71a1.png"/>-->
          <a class="font_5 text_11 ml-17 cursor-point"
             href="https://mp.weixin.qq.com/s/EB6Cwi1fsiSU4_rLPRjzrg"
             target="_blank">Mediation Center Bylaws</a>
        </div>
      </div>
    </div>
    <div class="flex-col mt-68">
      <div class="flex-col section_5">
        <span class="self-center font_3 text_12">Mediation process</span>
        <div class="flex-col self-stretch mt-79">
          <div class="flex-col items-center">
            <div class="flex-col justify-start items-center text-wrapper_1">
              <span class="font_5 text_13">Submit application intention and contact information</span>
            </div>
            <img class="image_6" src="../../assets/d53010f81da2d99bb40d5879a1e04543.png"/>
            <div class="flex-col justify-start items-start text-wrapper_9">
              <span class="font_4 text_14">
                The applicant fills in the Mediation Applicationform and submits the relevant materials of thecase
              </span>
            </div>
            <img class="image_6" src="../../assets/d53010f81da2d99bb40d5879a1e04543.png"/>
            <div class="flex-col justify-start items-center text-wrapper_4">
              <span class="font_6">The Center review materials</span>
            </div>
            <img class="image_6" src="../../assets/d53010f81da2d99bb40d5879a1e04543.png"/>
            <div class="flex-col justify-start items-center text-wrapper_2">
              <span class="font_5 text_15">If eligible, the Center accepts the case</span>
            </div>
            <img class="image_6" src="../../assets/d53010f81da2d99bb40d5879a1e04543.png"/>
            <div class="flex-col justify-start items-center text-wrapper_3">
              <span class="font_4 text_16">
                If both parties agree to mediation, they pay themediation fee in advance respectively and select(orthe
                Center appoints)a mediator
              </span>
            </div>
            <img class="image_6" src="../../assets/d53010f81da2d99bb40d5879a1e04543.png"/>
            <div class="flex-col justify-start items-center text-wrapper_7">
              <span class="font_5">The mediator presides the mediation meeting</span>
            </div>
            <img class="image_6" src="../../assets/d53010f81da2d99bb40d5879a1e04543.png"/>
          </div>
          <div class="flex-col items-center group_5">
            <div class="flex-col justify-start items-center text-wrapper_7">
              <span class="font_5">Parties reach a settlement agreement and signthe agreement</span>
            </div>
            <div class="flex-row equal-division_2 group_6">
              <div class="flex-col group_7 equal-division-item_2">
                <img class="self-center image_7" src="../../assets/d53010f81da2d99bb40d5879a1e04543.png"/>
                <div class="flex-col justify-start items-center self-stretch text-wrapper_8 text-wrapper_5">
                  <span class="font_6 text_19">The parties perform themediation agreement</span>
                </div>
              </div>
              <div class="flex-col group_7 equal-division-item_2">
                <img class="self-center image_7" src="../../assets/d53010f81da2d99bb40d5879a1e04543.png"/>
                <div class="flex-col justify-start items-center self-stretch text-wrapper_8 view_2">
                  <span class="text_18">
                    The parties may apply for judiciaconfirmation or arbitration rulingto make the mediation
                    agreementenforceable
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-col section_6">
        <span class="self-center font_3">Contact Information</span>
        <div class="flex-row justify-between equal-division mt-81">
          <div class="flex-row equal-division-item_3">
            <img class="self-center image_8" src="../../assets/74e70898fbf31bcb84c80de93ca1150c.png"/>
            <div class="flex-col items-start self-start ml-16">
              <span class="font_6 text_21">Contact Person</span>
              <span class="font_6 mt-15">Mr. Wu</span>
            </div>
          </div>
          <div class="section_1 horiz-divider"></div>
          <div class="flex-row equal-division-item_4">
            <img class="self-center image_9" src="../../assets/6866af0348e3a420d64708efc0b88bcf.png"/>
            <div class="flex-col items-start self-start ml-17">
              <span class="font_6 text_21">Contact Number</span>
              <span class="font_6 mt-14">18608909909</span>
            </div>
          </div>
          <div class="section_1 horiz-divider"></div>
          <div class="flex-row items-center equal-division-item_4">
            <img class="image_9" src="../../assets/a628ef4bef71099d74df9e50b278bf83.png"/>
            <div class="flex-col items-start ml-17">
              <span class="font_6 text_21">‌Email</span>
              <span class="font_6 mt-14">wuhuan@thunisoft.com</span>
            </div>
          </div>
        </div>
      </div>
      <common-bottom/>
    </div>
  </div>
</template>

<script>
import CommonBottom from "@/component/common-bottom.vue";

export default {
  components: {CommonBottom},
  props: {},
  data() {
    return {};
  },

  methods: {
    openVerify() {
      window.open('https://czt.jiulaw.cn/external-verify')
    },
    openNewWindow(url) {
      window.open(url, '_blank')
    },
  },
};
</script>

<style scoped lang="css">
.ml-3 {
  margin-left: 0.2196vw;
}

.mt-43 {
  margin-top: 3.1479vw;
}

.ml-17 {
  margin-left: 1.2445vw;
}

.mt-79 {
  margin-top: 5.7833vw;
}

.mt-81 {
  margin-top: 5.9297vw;
}

.mt-15 {
  margin-top: 1.0981vw;
}

.ml-21 {
  margin-left: 1.5373vw;
}

.mt-23 {
  margin-top: 1.6837vw;
}

.page {
  background-color: #ffffff;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.section {
  padding-bottom: 0.9956vw;
  background-image: url('../../assets/99b3393cd1aca143b493aeb89a140574.png');
  background-position: 0% 0%;
  background-size: 100vw 19.6903vw;
  background-repeat: no-repeat;
}

.section_2 {
  padding: 0.4744vw 6.2496vw 0.6252vw 6.2504vw;
  background-color: #fefeff29;
}

.image {
  width: 2.0498vw;
  height: 2.0498vw;
}

.text {
  color: #ffffff;
  font-size: 1.6669vw;
  font-family: 阿里妈妈数黑体;
  font-weight: 700;
}

.text-wrapper {
  background-image: linear-gradient(285deg, #f45a34 -36.1%, #ff9e36 99.4%);
  border-radius: 0.4165vw;
  width: 6.6669vw;
  height: 2.5vw;
}

.text_2 {
  color: #ffffff;
  font-size: 1.0417vw;
  font-family: Microsoft YaHei UI;
}

.group {
  padding: 5.0007vw 6.2496vw 5.0007vw 6.2504vw;
}

.font {
  font-size: 0.8331vw;
  font-family: Microsoft YaHei UI;
}

.text_3 {
  color: #ffffff;
}

.image_2 {
  width: 1.0249vw;
  height: 1.0249vw;
}

.text_4 {
  color: #ffffffa6;
}

.text_5 {
  color: #ffffff;
  font-size: 2.8126vw;
  font-family: Microsoft YaHei UI;
  font-weight: 700;
}

.group_2 {
  margin-right: 6.2504vw;
  margin-left: 6.2504vw;
  padding: 2.4239vw 3.2291vw 3.3353vw;
  border-radius: 0.4165vw;
  border: solid 0.05124vw #172b4d33;
}

.group_3 {
  width: 42.7167vw;
}

.image_3 {
  width: 4.978vw;
  height: 4.978vw;
}

.group_4 {
  height: 6.2262vw;
}

.text_8 {
  color: #172b4d;
  font-size: 1.6669vw;
  font-family: Microsoft YaHei UI;
  font-weight: 700;
  line-height: 2.7606vw;
}

.font_6 {
  font-size: 1.1457vw;
  font-family: Microsoft YaHei UI;
  color: #172b4d;
}

.text_6 {
  word-break: break-all;
}

.text_7 {
  color: #172b4da6;
  line-height: 2.7606vw;
}

.text_9 {
  line-height: 2.1874vw;
}

.equal-division_1 {
  align-self: stretch;
  margin-right: 0.1061vw;
}

.equal-division-item {
  flex: 1 1 39.7914vw;
}

.section_3 {
  padding: 1.407vw 9.7753vw 1.8748vw;
  background-color: #e3e9f2;
  border-radius: 0.4165vw;
  height: 5.0007vw;
}

.image_4 {
  border-radius: 0.3126vw;
  width: 1.2504vw;
  height: 1.2504vw;
}

.section_4 {
  padding: 1.407vw 8.6523vw 1.8748vw 9.1662vw;
  background-color: #e3e9f2;
  border-radius: 0.4165vw;
  height: 5.0007vw;
}

.image_5 {
  border-radius: 0.3126vw;
  width: 1.2504vw;
  height: 1.2504vw;
}

.font_5 {
  font-size: 1.1457vw;
  font-family: Microsoft YaHei UI;
  line-height: 1.2072vw;
  color: #172b4d;
}

.text_11 {
  line-height: 1.2006vw;
}

.section_5 {
  padding: 5.0007vw 0 4.481vw;
  background-color: #e3e9f24d;
}

.font_3 {
  font-size: 2.8126vw;
  font-family: Microsoft YaHei UI;
  color: #172b4d;
}

.text_12 {
  line-height: 2.9473vw;
}

.text-wrapper_1 {
  padding: 2.1852vw 0 2.4348vw;
  background-color: #e3e9f2;
  border-radius: 0.4165vw;
  width: 46.8748vw;
}

.text_13 {
  line-height: 1.2138vw;
}

.image_6 {
  width: 2.489vw;
  height: 2.489vw;
}

.text-wrapper_9 {
  padding: 0.6479vw 0 1.4524vw;
  background-color: #e3e9f2;
  border-radius: 0.4165vw;
  width: 46.8748vw;
}

.font_4 {
  font-size: 1.1457vw;
  font-family: Microsoft YaHei UI;
  line-height: 1.8748vw;
  color: #172b4d;
}

.text_14 {
  margin-left: 2.604vw;
  text-align: center;
  word-break: break-all;
  width: 37.3353vw;
}

.text-wrapper_4 {
  padding: 2.1852vw 0 2.7182vw;
  background-color: #e3e9f2;
  border-radius: 0.4165vw;
  width: 46.8748vw;
}

.text-wrapper_2 {
  padding: 2.1852vw 0 2.4275vw;
  background-color: #e3e9f2;
  border-radius: 0.4165vw;
  width: 46.8748vw;
}

.text_15 {
  line-height: 1.2211vw;
}

.text-wrapper_3 {
  padding: 0.6479vw 0 1.4392vw;
  background-color: #e3e9f2;
  border-radius: 0.4165vw;
  width: 46.8748vw;
}

.text_16 {
  text-align: center;
  width: 40.1903vw;
}

.text-wrapper_7 {
  padding: 2.1852vw 0 2.4414vw;
  background-color: #e3e9f2;
  border-radius: 0.4165vw;
  width: 46.8748vw;
}

.group_5 {
  height: 14.6881vw;
}

.equal-division_2 {
  position: relative;
  margin-top: -0.5212vw;
}

.group_6 {
  width: 49.063vw;
}

.group_7 {
  flex: 1 1 24.5315vw;
}

.equal-division-item_2 {
  padding: 0.5154vw 1.0937vw 0.5212vw;
}

.image_7 {
  width: 2.5vw;
  height: 2.5vw;
}

.text-wrapper_8 {
  margin-right: 0.1559vw;
}

.text-wrapper_5 {
  padding: 1.3067vw 0 1.5447vw;
  background-color: #e3e9f2;
  border-radius: 0.4165vw;
}

.text_19 {
  line-height: 1.3909vw;
  text-align: center;
  width: 19.0337vw;
}

.view_2 {
  padding: 1.0769vw 0 1.3163vw;
  background-color: #e3e9f2;
  border-radius: 0.4165vw;
}

.text_18 {
  color: #172b4d;
  font-size: 0.9378vw;
  font-family: Microsoft YaHei UI;
  line-height: 1.0981vw;
  text-align: center;
  width: 19.2533vw;
}

.section_6 {
  padding: 5.0007vw 15.6852vw 4.6779vw 21.0417vw;
  background-image: url('../../assets/aa8a50273375003b2666d2ec49d7a85c.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.equal-division {
  align-self: stretch;
}

.equal-division-item_3 {
  padding: 0.5205vw 0 0.5212vw;
}

.image_8 {
  width: 3.3675vw;
  height: 3.3675vw;
}

.section_1 {
  margin: 0.6252vw 0 0.3455vw;
}

.horiz-divider {
  background-color: #172b4d33;
  width: 0.05198vw;
  height: 3.5944vw;
}

.equal-division-item_4 {
  padding: 0.5205vw 0 0.5549vw;
}

.image_9 {
  border-radius: 50%;
  width: 3.3331vw;
  height: 3.3338vw;
}

.section_8 {
  padding: 0 0.9195vw;
  background-color: #172b4d;
}

.group_8 {
  margin-right: 5.2013vw;
  padding: 1.6669vw 0;
}

.group_9 {
  margin-top: 0.2086vw;
  padding: 0 5.3302vw;
}

.font_7 {
  font-size: 1.1457vw;
  font-family: 微软雅黑;
  color: #ffffff;
}

.text_21 {
  opacity: 0.65;
}

.section_9 {
  background-color: #ffffff40;
  width: 0.05198vw;
  height: 5.0007vw;
}

.group_10 {
  margin-left: 5.4165vw;
  margin-top: 0.2086vw;
}

.text_27 {
  font-weight: 700;
}

.text_31 {
  margin-left: 3.3309vw;
  margin-top: 3.6603vw;
}

.text_32 {
  margin-left: 3.3309vw;
  margin-top: 3.6603vw;
}

.text_33 {
  margin-left: 3.3309vw;
  margin-top: 3.6603vw;
}

.group_11 {
  margin-left: 5.3309vw;
  margin-right: 5.1999vw;
  padding: 0.8331vw 0 0.8148vw;
  border-top: solid 0.05198vw #ffffff40;
}

.image_10 {
  width: 2.1962vw;
  height: 1.6837vw;
}
</style>
