<template>
  <div class="flex-col page">
    <div class="flex-col section">
      <div class="flex-col section_2">
        <div class="flex-row justify-between items-center section_3">
          <div class="flex-row">
            <img class="self-center image" src="../../assets/6f7ef0b09187f296c1dc6a4af0c3d406.png" />
            <span class="self-start text ml-18">数澄保</span>
          </div>
          <div class="flex-col justify-center items-center text-wrapper" style="cursor: pointer" @click="openVerify()"><span class="text_2">证据查验</span></div>
        </div>
        <div class="flex-col group">
          <div class="flex-row items-center self-stretch">
            <a class="font text_3 cursor-point" href="/">首页</a>
            <img class="image_2 ml-3" src="../../assets/cb03801bd1ee1f59153dcb0b010ba28a.png" />
            <span class="font text_4 ml-3">存证取证服务</span>
          </div>
          <span class="self-start font_2 text_5 mt-14">存证取证服务</span>
        </div>
      </div>
      <div class="flex-col justify-start group_2">
        <div class="grid">
          <div class="flex-col items-center grid-item_1">
            <img class="image_3" src="../../assets/11b4c12284bdebbbc09e2f05afaee3ef.png" />
            <span class="font_3 mt-21">律师取证中心</span>
          </div>
          <div class="flex-col items-center grid-item">
            <img class="image_3" src="../../assets/7fbd7bdad28c83905dd63e752e6b7ede.png" />
            <span class="font_3 mt-21">知识产权保护</span>
          </div>
          <div class="flex-col items-center grid-item_3">
            <img class="image_3" src="../../assets/71ec2afb163a30c7cb6ddd1b746188db.png" />
            <span class="font_3 mt-21">企业取证管理</span>
          </div>
          <div class="flex-col items-center grid-item_4">
            <img class="image_3" src="../../assets/e0d9e1813408ca6ca0f7142b98f64200.png" />
            <span class="font_3 mt-21">行政执法人员</span>
          </div>
          <div class="flex-col items-center grid-item_5">
            <img class="image_3" src="../../assets/994c89297cb8a1f6c4cd1b6dd4a866c6.png" />
            <span class="font_3 mt-21">个人取证存证</span>
          </div>
          <div class="flex-col items-center grid-item_3">
            <img class="image_3" src="../../assets/ba5b1935c1696c8875ddb1f3209d60f4.png" />
            <span class="font_3 mt-21">特殊行业取证</span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-row justify-center items-center group_3 mt-85">
      <div class="flex-col justify-start image-wrapper">
        <img class="image_4" src="../../assets/135146cfc43cd86079191c2f3bb81413.png" />
      </div>
      <div class="flex-col group_4 ml-81">
        <div class="flex-col items-start">
          <span class="font_3 text_6">存证平台——让数据真实可靠</span>
          <span class="font_2 text_7 mt-17">区块链存证取证服务</span>
        </div>
        <span class="font_3 text_8">
          提供电子证据一站式存证、查验、取证、鉴证等服务。权威凭证保障电子证据的原始性、完整性。
        </span>
        <div class="flex-col items-start group_5">
          <div class="group_6">
            <span class="font_4">电子存证 / 电子取证：</span>
            <a class="font_5 text_10 text_1 cursor-point" href="https://czt.jiulaw.cn" target="_blank">https://czt.jiulaw.cn</a>
          </div>
          <div class="group_7 mt-19">
            <span class="font_4">证据核验 / 法院核验：</span>
            <a class="font_5 text_10 cursor-point" href="https://czt.jiulaw.cn/external-verify" target="_blank">https://czt.jiulaw.cn/external-verify</a>
          </div>
        </div>
      </div>
    </div>
    <common-bottom class="flex-col justify-start relative mt-85"/>
  </div>
</template>

<script>
  import CommonBottom from "@/component/common-bottom.vue";

  export default {
    components: {CommonBottom},
    props: {},
    data() {
      return {};
    },

    methods: {
      openVerify(){
        window.open('https://czt.jiulaw.cn/external-verify')
      },
    },
  };
</script>

<style scoped lang="css">
  .ml-3 {
    margin-left: 0.2196vw;
  }
  .mt-21 {
    margin-top: 1.5373vw;
  }
  .ml-81 {
    margin-left: 5.9297vw;
  }
  .mt-17 {
    margin-top: 1.2445vw;
  }
  .mt-19 {
    margin-top: 1.3909vw;
  }
  .mt-85 {
    margin-top: 6.2225vw;
  }
  .ml-21 {
    margin-left: 1.5373vw;
  }
  .mt-23 {
    margin-top: 1.6837vw;
  }
  .page {
    background-color: #ffffff;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
  .section {
    background-color: #e3e9f24d;
  }
  .section_2 {
    padding-bottom: 1.0139vw;
    background-image: url('../../assets/c7eac9a6fcff5baf95cc388e916e38c5.png');
    background-position: 0% 0%;
    background-size: 100vw 19.6837vw;
    background-repeat: no-repeat;
  }
  .section_3 {
    padding: 0.4736vw 6.2496vw 0.6252vw 6.2504vw;
    background-color: #fefeff29;
  }
  .image {
    width: 2.0498vw;
    height: 2.0498vw;
  }
  .text {
    color: #ffffff;
    font-size: 1.6669vw;
    font-family: 阿里妈妈数黑体;
    font-weight: 700;
  }
  .text-wrapper {
    background-image: linear-gradient(285deg, #f45a34 -36.1%, #ff9e36 99.4%);
    border-radius: 0.4165vw;
    width: 6.6669vw;
    height: 2.4993vw;
  }
  .text_2 {
    color: #ffffff;
    font-size: 1.0417vw;
    font-family: Microsoft YaHei UI;
  }
  .group {
    padding: 4.9985vw 6.2321vw;
  }
  .font {
    font-size: 0.8331vw;
    font-family: Microsoft YaHei UI;
  }
  .text_3 {
    color: #ffffff;
  }
  .image_2 {
    width: 1.0249vw;
    height: 1.0249vw;
  }
  .text_4 {
    color: #ffffffa6;
  }
  .font_2 {
    font-size: 2.8126vw;
    font-family: Microsoft YaHei UI;
  }
  .text_5 {
    color: #ffffff;
    font-weight: 700;
  }
  .group_2 {
    padding: 6.254vw 0 6.2533vw;
  }
  .grid {
    margin-left: 6.2504vw;
    margin-right: 6.3367vw;
    height: 38.7994vw;
    display: grid;
    grid-template-rows: repeat(2, minmax(0, 1fr));
    grid-template-columns: repeat(3, minmax(0, 1fr));
    row-gap: 1.7218vw;
    column-gap: 1.7709vw;
  }
  .grid-item_1 {
    padding-bottom: 1.9553vw;
    background-color: #ffffff;
    border-radius: 0.4165vw;
  }
  .image_3 {
    width: 28.0381vw;
    height: 13.9824vw;
  }
  .font_3 {
    font-size: 1.1457vw;
    font-family: Microsoft YaHei UI;
    color: #172b4d;
  }
  .grid-item {
    padding-bottom: 1.9583vw;
    background-color: #ffffff;
    border-radius: 0.4165vw;
  }
  .grid-item_3 {
    padding-bottom: 1.9671vw;
    background-color: #ffffff;
    border-radius: 0.4165vw;
  }
  .grid-item_4 {
    padding-bottom: 1.9524vw;
    background-color: #ffffff;
    border-radius: 0.4165vw;
  }
  .grid-item_5 {
    padding-bottom: 1.9627vw;
    background-color: #ffffff;
    border-radius: 0.4165vw;
  }
  .group_3 {
    padding-left: 6.2504vw;
    padding-right: 6.2152vw;
  }
  .image-wrapper {
    padding: 0.9861vw 0 0.7965vw;
    background-image: url('../../assets/1fbc463b1078ccab8f4b9fa165cd3214.png');
    background-position: 0% 0%;
    background-size: 43.7504vw 29.1618vw;
    background-repeat: no-repeat;
    width: 43.7504vw;
    height: 29.1618vw;
  }
  .image_4 {
    margin-left: 2.4305vw;
    margin-right: 2.9597vw;
    width: 38.3602vw;
    height: 27.3792vw;
  }
  .group_4 {
    width: 37.8558vw;
  }
  .text_6 {
    color: #0147be;
    font-weight: 700;
  }
  .text_7 {
    color: #172b4d;
  }
  .text_8 {
    margin-top: 3.7548vw;
    line-height: 2.1874vw;
  }
  .group_5 {
    margin-top: 6.2357vw;
  }
  .group_6 {
    line-height: 0.8785vw;
  }
  .font_4 {
    font-size: 0.8331vw;
    font-family: 微软雅黑;
    color: #172b4da6;
  }
  .font_5 {
    font-size: 0.8331vw;
    font-family: 微软雅黑;
    line-height: 0.888vw;
    color: #0147be;
  }
  .text_10 {
    text-decoration: underline;
  }
  .text_1 {
    line-height: 0.8785vw;
  }
  .group_7 {
    line-height: 0.888vw;
  }
  .section_4 {
    background-color: #172b4d;
    height: 9.9985vw;
  }
  .section_5 {
    padding: 0 0.9195vw;
    background-color: #172b4d;
  }
  .pos {
    position: absolute;
    left: 0;
    right: 0;
    top: 1.5102vw;
  }
  .group_9 {
    margin-right: 5.2013vw;
    padding: 1.6662vw 0;
  }
  .group_10 {
    margin-top: 0.2086vw;
    padding: 0 5.3302vw;
  }
  .font_6 {
    font-size: 1.1457vw;
    font-family: 微软雅黑;
    color: #ffffff;
  }
  .text_13 {
    opacity: 0.65;
  }
  .section_6 {
    background-color: #ffffff40;
    width: 0.05198vw;
    height: 4.9993vw;
  }
  .group_11 {
    margin-left: 5.4165vw;
    margin-top: 0.2086vw;
  }
  .text_16 {
    font-weight: 700;
  }
  .text_20 {
    margin-left: 3.3309vw;
    margin-top: 3.6603vw;
  }
  .text_21 {
    margin-left: 3.3309vw;
    margin-top: 3.6603vw;
  }
  .text_22 {
    margin-left: 3.3309vw;
    margin-top: 3.6603vw;
  }
  .group_12 {
    margin-left: 5.3309vw;
    margin-right: 5.1999vw;
    padding: 0.8331vw 0 0.7138vw;
    border-top: solid 0.05198vw #ffffff40;
  }
  .image_5 {
    width: 2.1962vw;
    height: 1.6837vw;
  }
</style>
