<template>
  <div class="flex-col page">
    <div class="flex-col section">
      <div class="flex-row justify-between items-center section_2">
        <div class="flex-row">
          <img class="self-center image" src="../../assets/6f7ef0b09187f296c1dc6a4af0c3d406.png"/>
          <span class="self-start text ml-18">数澄保</span>
        </div>
        <div class="flex-col justify-center items-center text-wrapper" style="cursor: pointer" @click="openVerify()">
          <span class="text_2">证据查验</span></div>
      </div>
      <div class="flex-col group">
        <div class="flex-row items-center self-stretch">
          <a class="font text_3 cursor-point" href="/">首页</a>
          <img class="image_2 ml-3" src="../../assets/5ede9e4ecda5b3f787cbbb15e4ad1974.png"/>
          <span class="font text_4 ml-3">数字跨境争议解决服务</span>
        </div>
        <span class="self-start font_2 text_5 mt-14">数字跨境争议解决服务</span>
      </div>
    </div>
    <div class="flex-col group_2 mt-68">
      <div class="flex-row items-center self-start group_3">
        <img class="image_3" src="../../assets/6f7ef0b09187f296c1dc6a4af0c3d406.png"/>
        <div class="flex-1 group_4 ml-16">
          <span class="text_7">
            海口国际商事调解中心简介
            <br/>
          </span>
          <span class="font_3 text_6">Introduction of Haikou International Commercial Mediation Center</span>
        </div>
      </div>
      <span class="self-stretch font_3 text_8 mt-43">
        为落实《海南自由贸易港建设总体方案》，构建商事纠纷多元解决机制。2020年6月29日，海口市政府通过《关于推动设立海口国际商事调解中心实施方案》，同日，海口国际商事调解中心（下称“中心”）经民政部门批准成立。中心作为独立的第三方调解机构，是海南批准成立的第一家民办非企业性质的专业从事商事纠纷调解的机构。
      </span>
      <div class="flex-row equal-division mt-43">
        <div class="flex-row justify-center items-center section_3 equal-division-item cursor-point"
             @click="openNewWindow('https://mp.weixin.qq.com/s/Yr_LX_WPa5w0RRrExePd1w')">
          <!--          <img class="self-center image_4" src="../../assets/8c8ea465f174bc96d5edf554639f71a1.png" />-->
          <a class="self-start font_3 ml-17 cursor-point" href="https://mp.weixin.qq.com/s/Yr_LX_WPa5w0RRrExePd1w"
             target="_blank">调解规则</a>
        </div>
        <div class="flex-row justify-center section_3 equal-division-item ml-17 cursor-point"
             @click="openNewWindow('https://mp.weixin.qq.com/s/EB6Cwi1fsiSU4_rLPRjzrg')">
          <!--          <img class="self-center image_4" src="../../assets/8c8ea465f174bc96d5edf554639f71a1.png" />-->
          <a class="self-start font_3 ml-17 cursor-point" href="https://mp.weixin.qq.com/s/EB6Cwi1fsiSU4_rLPRjzrg"
             target="_blank">调解中心章程</a>
        </div>
      </div>
    </div>
    <div class="flex-col mt-68">
      <div class="flex-col section_4">
        <span class="self-center font_2">调解流程</span>
        <div class="flex-col self-stretch mt-82">
          <div class="flex-col items-center self-stretch">
            <div class="flex-col justify-start items-center text-wrapper_1">
              <span class="font_3">提交申请意向及联系方式</span>
            </div>
            <img class="image_5" src="../../assets/d53010f81da2d99bb40d5879a1e04543.png"/>
            <div class="flex-col justify-start items-center text-wrapper_8">
              <span class="font_3 text_12">申请人填写《调解申请书》，提交案件相关材料</span>
            </div>
            <img class="image_5" src="../../assets/d53010f81da2d99bb40d5879a1e04543.png"/>
            <div class="flex-col justify-start items-center text-wrapper_1">
              <span class="font_3">调解中心审查材料</span>
            </div>
            <img class="image_5" src="../../assets/d53010f81da2d99bb40d5879a1e04543.png"/>
            <div class="flex-col justify-start items-center text-wrapper_1">
              <span class="font_3">符合条件的，中心受理案件</span>
            </div>
            <img class="image_5" src="../../assets/d53010f81da2d99bb40d5879a1e04543.png"/>
            <div class="flex-col justify-start items-center text-wrapper_3">
              <span class="font_3 text_15">双方同意调解的，分别预交调解费选(指)定调解员</span>
            </div>
            <img class="image_5" src="../../assets/d53010f81da2d99bb40d5879a1e04543.png"/>
            <div class="flex-col justify-start items-center text-wrapper_5">
              <span class="font_3">调解员主持调解会议</span>
            </div>
            <img class="image_5" src="../../assets/d53010f81da2d99bb40d5879a1e04543.png"/>
          </div>
          <div class="flex-col justify-start items-center self-center text-wrapper_2">
            <span class="font_3">达成和解，签署协议</span>
          </div>
          <div class="flex-row equal-division_2 group_5">
            <div class="flex-col group_6 group_1">
              <img class="self-center image_6" src="../../assets/d53010f81da2d99bb40d5879a1e04543.png"/>
              <div class="flex-col justify-start items-center self-stretch view">
                <span class="font_3">当事人履行调解协议</span>
              </div>
            </div>
            <div class="flex-col group_6 group_15">
              <img class="self-center image_6" src="../../assets/d53010f81da2d99bb40d5879a1e04543.png"/>
              <div class="flex-col justify-center items-center self-stretch text-wrapper_4">
                <span class="font_3 text_19">当事人可申请法院司法确认或仲裁裁定赋予调解协议法律强制执行力</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-col section_5">
        <span class="self-center font_2">联系我们</span>
        <div class="flex-row justify-evenly self-stretch group_7 mt-83">
          <div class="flex-row items-center group_8">
            <img class="image_7" src="../../assets/14c4a51fa166de63b5694d22d0cc2ec8.png"/>
            <div class="flex-col items-center ml-16">
              <span class="font_3 text_21">联系人</span>
              <span class="font_3 mt-12">吴先生</span>
            </div>
          </div>
          <div class="flex-row items-center group_9">
            <img class="image_8" src="../../assets/62325843fd1be8d07b702f5308cb1cb7.png"/>
            <div class="flex-col items-start ml-17">
              <span class="font_3 text_21">联系电话</span>
              <span class="font_3 mt-12">18608909909</span>
            </div>
          </div>
          <div class="flex-row items-center group_10">
            <img class="image_8" src="../../assets/c23e4596a5d1e02798b56417e92faabb.png"/>
            <div class="flex-col items-start ml-17">
              <span class="font_3 text_21">电子邮箱</span>
              <span class="font_3 mt-12">wuhuan@thunisoft.com</span>
            </div>
          </div>
        </div>
      </div>
      <common-bottom/>
    </div>
  </div>
</template>

<script>
import CommonBottom from "@/component/common-bottom.vue";

export default {
  components: {CommonBottom},
  props: {},
  data() {
    return {};
  },

  methods: {
    openVerify() {
      window.open('https://czt.jiulaw.cn/external-verify')
    },

    openNewWindow(url) {
      window.open(url, '_blank')
    },
  },
};
</script>

<style scoped lang="css">
.ml-3 {
  margin-left: 0.2196vw;
}

.mt-43 {
  margin-top: 3.1479vw;
}

.ml-17 {
  margin-left: 1.2445vw;
}

.mt-83 {
  margin-top: 6.0761vw;
}

.ml-21 {
  margin-left: 1.5373vw;
}

.mt-23 {
  margin-top: 1.6837vw;
}

.page {
  background-color: #ffffff;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.section {
  padding-bottom: 0.9949vw;
  background-image: url('../../assets/0f13d8fabaa8c00e148f127b0715571c.png');
  background-position: 0% 0%;
  background-size: 100vw 19.6911vw;
  background-repeat: no-repeat;
}

.section_2 {
  padding: 0.4744vw 6.2496vw 0.6245vw 6.2504vw;
  background-color: #fefeff29;
}

.image {
  width: 2.0498vw;
  height: 2.0498vw;
}

.text {
  color: #ffffff;
  font-size: 1.6669vw;
  font-family: 阿里妈妈数黑体;
  font-weight: 700;
}

.text-wrapper {
  background-image: linear-gradient(285deg, #f45a34 -36.1%, #ff9e36 99.4%);
  border-radius: 0.4165vw;
  width: 6.6669vw;
  height: 2.5007vw;
}

.text_2 {
  color: #ffffff;
  font-size: 1.0417vw;
  font-family: Microsoft YaHei UI;
}

.group {
  padding: 5.0015vw 6.2496vw 5.0022vw 6.2504vw;
}

.font {
  font-size: 0.8331vw;
  font-family: Microsoft YaHei UI;
}

.text_3 {
  color: #ffffff;
}

.image_2 {
  width: 1.0249vw;
  height: 1.0249vw;
}

.text_4 {
  color: #ffffffa6;
}

.font_2 {
  font-size: 2.8126vw;
  font-family: Microsoft YaHei UI;
  color: #172b4d;
}

.text_5 {
  color: #ffffff;
  font-weight: 700;
}

.group_2 {
  margin-right: 6.2504vw;
  margin-left: 6.2504vw;
  padding: 2.4246vw 3.2291vw 3.3353vw;
  border-radius: 0.4165vw;
  border: solid 0.05124vw #172b4d33;
}

.group_3 {
  width: 42.7167vw;
}

.image_3 {
  width: 4.978vw;
  height: 4.978vw;
}

.group_4 {
  height: 6.2262vw;
}

.text_7 {
  color: #172b4d;
  font-size: 1.6669vw;
  font-family: Microsoft YaHei UI;
  font-weight: 700;
  line-height: 2.7606vw;
}

.font_3 {
  font-size: 1.1457vw;
  font-family: Microsoft YaHei UI;
  color: #172b4d;
}

.text_6 {
  color: #172b4da6;
  line-height: 2.7606vw;
  word-break: break-all;
}

.text_8 {
  line-height: 2.1874vw;
  text-align: justify;
}

.equal-division {
  align-self: stretch;
  margin-right: 0.1061vw;
}

.section_3 {
  flex: 1 1 39.7914vw;
}

.equal-division-item {
  padding: 1.4063vw 0 1.8755vw;
  background-color: #e3e9f2;
  border-radius: 0.4165vw;
  height: 5.0007vw;
}

.image_4 {
  border-radius: 0.3126vw;
  width: 1.2504vw;
  height: 1.2504vw;
}

.section_4 {
  padding: 5.0007vw 0 4.6889vw;
  background-color: #e3e9f24d;
}

.text-wrapper_1 {
  padding: 1.9656vw 0 2.7467vw;
  background-color: #e3e9f2;
  border-radius: 0.4165vw;
  width: 46.8748vw;
}

.image_5 {
  width: 2.489vw;
  height: 2.489vw;
}

.text-wrapper_8 {
  padding: 1.9656vw 0 2.7452vw;
  background-color: #e3e9f2;
  border-radius: 0.4165vw;
  width: 46.8748vw;
}

.text_12 {
  margin-left: 2.604vw;
}

.text-wrapper_3 {
  padding: 1.9656vw 0 2.7057vw;
  background-color: #e3e9f2;
  border-radius: 0.4165vw;
  width: 46.8748vw;
}

.text_15 {
  margin-left: 2.604vw;
  line-height: 1.1633vw;
}

.text-wrapper_5 {
  padding: 1.9656vw 0 2.7489vw;
  background-color: #e3e9f2;
  border-radius: 0.4165vw;
  width: 46.8748vw;
}

.text-wrapper_2 {
  padding: 1.9656vw 0 2.757vw;
  background-color: #e3e9f2;
  border-radius: 0.4165vw;
  width: 46.8748vw;
}

.equal-division_2 {
  align-self: center;
}

.group_5 {
  width: 49.063vw;
}

.group_6 {
  flex: 1 1 24.5315vw;
}

.group_1 {
  padding: 0 1.0937vw 0.5205vw;
}

.image_6 {
  width: 2.5vw;
  height: 2.5vw;
}

.view {
  margin-right: 0.1559vw;
  padding: 1.9649vw 0 2.7584vw;
  background-color: #e3e9f2;
  border-radius: 0.4165vw;
  width: 22.1874vw;
}

.group_15 {
  padding: 0 1.0937vw;
}

.text-wrapper_4 {
  background-color: #e3e9f2;
  border-radius: 0.4165vw;
  width: 22.1874vw;
  height: 6.3551vw;
}

.text_19 {
  margin: 0 1.1457vw;
  text-align: center;
}

.section_5 {
  padding: 5.0007vw 10.6852vw 5.0234vw 15.6852vw;
  background-image: url('../../assets/5a552bd6f0ae6af5204e1deea6c68b40.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.group_7 {
  padding-left: 5.3287vw;
}

.group_8 {
  padding-bottom: 0.175vw;
  width: 17.9963vw;
  border-right: solid 0.05198vw #172b4d33;
}

.image_7 {
  margin-left: 4.9239vw;
  width: 3.3675vw;
  height: 3.3675vw;
}

.group_9 {
  padding: 0 4.7826vw 0.2086vw 5vw;
  border-right: solid 0.05198vw #172b4d33;
}

.image_8 {
  border-radius: 50%;
  width: 3.3331vw;
  height: 3.3338vw;
}

.group_10 {
  margin-bottom: 0.2086vw;
  padding: 0 5vw;
}

.section_6 {
  padding: 0 0.9195vw;
  background-color: #172b4d;
}

.group_11 {
  margin-right: 5.2013vw;
  padding: 1.6669vw 0;
}

.group_12 {
  margin-top: 0.2086vw;
  padding: 0 5.3302vw;
}

.font_4 {
  font-size: 1.1457vw;
  font-family: 微软雅黑;
  color: #ffffff;
}

.text_21 {
  opacity: 0.65;
}

.section_7 {
  background-color: #ffffff40;
  width: 0.05198vw;
  height: 5.0007vw;
}

.group_13 {
  margin-left: 5.4165vw;
  margin-top: 0.2086vw;
}

.text_29 {
  font-weight: 700;
}

.text_33 {
  margin-left: 3.3309vw;
  margin-top: 3.4407vw;
}

.text_34 {
  margin-left: 3.3309vw;
  margin-top: 3.6603vw;
}

.text_35 {
  margin-left: 3.3309vw;
  margin-top: 3.6603vw;
}

.group_14 {
  margin-left: 5.3309vw;
  margin-right: 5.1999vw;
  padding: 0.8338vw 0 0.765vw;
  border-top: solid 0.05198vw #ffffff40;
}

.image_9 {
  width: 2.1962vw;
  height: 1.6837vw;
}
</style>
