<template>
  <div class="flex-col page">
    <div class="flex-col section">
      <div class="flex-col self-stretch section_2" ref="refBannerDiv">
        <div class="flex-row justify-between items-center self-stretch section_3">
          <div class="flex-row">
            <img class="self-center image" src="../../assets/6f7ef0b09187f296c1dc6a4af0c3d406.png"/>
            <span class="self-start text ml-16">数澄保</span>
          </div>
          <div class="flex-col justify-center items-center text-wrapper" style="cursor: pointer" @click="openVerify()">
            <span class="text_2">证据查验</span></div>
        </div>
        <span class="self-start text_3 mt-84" v-if="index==0">数据权益风险预防<br/>及数字纠纷多元化解平台</span>
        <span class="self-start text_3 mt-84" v-else>司法推动企业风险提前预防<br/>营造公平可信法制化营商环境</span>
      </div>
      <span class="self-center font text_4">数据权益风险预防和保护</span>
      <div class="flex-row equal-division_1">
        <div class="flex-col section_4 equal-division-item">
          <img class="image_2" src="../../assets/a30be53a6fe4b8c62c60ea17222fa9ef.png"/>
          <div class="flex-row justify-between group mt-24" style="cursor: pointer" @click="openWindow('page_qyfxts')">
            <span class="self-center font_2">数据权益风险提示</span>
            <img class="image_3" src="../../assets/81ad4c55b45a26fdaae878d35fb44044.png"/>
          </div>
        </div>
        <div class="flex-col section_4 equal-division-item ml-24">
          <img class="image_2" src="../../assets/04e0f58da8ef3cd29db6ede04b20974b.png"/>
          <div class="flex-row justify-between group mt-24" style="cursor: pointer" @click="openWindow('page_czqzfw')">
            <span class="self-center font_2">区块链存证取证服务</span>
            <img class="image_3" src="../../assets/4da073628a3e5c230b5227eccc658cab.png"/>
          </div>
        </div>
        <div class="flex-col section_4 equal-division-item ml-24">
          <img class="image_2" src="../../assets/e6eae777496beb4ff424485f92b5e087.png"/>
          <div class="flex-row justify-between group mt-24" style="cursor: pointer" @click="openWindow('page_zscqjd')">
            <span class="self-center font_2">知识产权鉴定服务</span>
            <img class="image_3" src="../../assets/5fe65884b7623605f5b6d6ec3380fa9a.png"/>
          </div>
        </div>
        <div class="flex-col section_4 equal-division-item ml-24">
          <img class="image_2" src="../../assets/f5049d1275c3ee25a733c9a27d7c0127.png"/>
          <div class="flex-row justify-between group mt-24" style="cursor: pointer" @click="openWindow('page_sjhg')">
            <span class="self-center font_2">数据合规及质量评价</span>
            <img class="image_3" src="../../assets/4da073628a3e5c230b5227eccc658cab.png"/>
          </div>
        </div>
      </div>
      <span class="self-center font text_5">数字纠纷多元化解</span>
      <div class="flex-row equal-division_1">
        <div class="flex-row items-center section_5 equal-division-item_2">
          <img class="flex-1 image_4" src="../../assets/741f166e8f38cbb37261631df287d6bb.png"/>
          <div class="flex-col items-start group_2 ml-24">
            <span class="font_2">知识产权解纷服务</span>
            <div class="flex-col justify-center items-center text-wrapper_1 mt-20" style="cursor: pointer"
                 @click="openWindow('page_szkj_zh')">
              <span class="font_1">申请调解</span>
            </div>
          </div>
        </div>
        <div class="flex-row items-center section_5 equal-division-item_2 ml-24">
          <img class="flex-1 image_4" src="../../assets/f3b674d258d886d1d194901e90d20744.png"/>
          <div class="flex-col items-start group_3 ml-24">
            <span class="font_2">数字跨境争议解决服务</span>
            <div class="flex-col justify-center items-center text-wrapper_1 mt-20" style="cursor: pointer"
                 @click="openWindow('page_szkj_en')">
              <span class="font_1">申请调解</span>
            </div>
          </div>
        </div>
        <div class="flex-row items-center section_5 equal-division-item_2 ml-24">
          <img class="image_5" src="../../assets/9dfe1b2948f47905b7b1dcc97be727ae.png"/>
          <div class="flex-col ml-24">
            <span class="font_2">调解系统登录</span>
            <div class="flex-col justify-center items-center view mt-20" style="cursor: pointer"
                 @click="openWindow('page_tjfwxtdl')">
              <span class="font_1">登录系统</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    <div class="flex-col justify-start items-center relative text-wrapper_3">-->
    <!--      <span class="font text_11">司法服务联盟链可信数据空间</span>-->
    <!--     -->
    <!--    </div>-->
    <div class="fd-qylfn">
      <div class="fd-qylfnbox">
        <div class="fd-qylfn-content">
          <h2 class="fd-qylfn-title">司法服务联盟链可信数据空间</h2>
          <div class="fd-qylfn-left">
            <table class="fd-qylfn-table">
              <thead>
              <td>区块高度</td>
              <td>存证hash</td>
              <td>存证时间</td>
              </thead>
              <tr>
                <td>43112255</td>
                <td>7e9a432c95720929c7ba9cdbbda9fda59672fb1585aa317c86b444123d5</td>
                <td>2025-03-20 14:31:57</td>
              </tr>
              <tr>
                <td>43112254</td>
                <td>0dc688e701cf33f6f592daa07f813b3a2b015cb593d6a545b7fe9fca5ffd5</td>
                <td>2025-03-20 14:31:54</td>
              </tr>
              <tr>
                <td>43112253</td>
                <td>eeffaacbd539290eb5ba394001b3c6c6725437a59eeb254dc4ea998c8c</td>
                <td>2025-03-20 14:31:51</td>
              </tr>
              <tr>
                <td>43112252</td>
                <td>27f962dddd599a19643106ec443f34f944266870d6b1ea0396cdc01d0860</td>
                <td>2025-03-20 14:31:48</td>
              </tr>
              <tr>
                <td>43112251</td>
                <td>c8c1cc00e7434786890c56faebb6982850eb3254b81135ea24fb3bf339eda4</td>
                <td>2025-03-20 14:31:45</td>
              </tr>
              <tr>
                <td>43112250</td>
                <td>6b025eccf94d3ba66cfb0084873c39d30278e88e845086c6852066b2981</td>
                <td>2025-03-20 14:31:42</td>
              </tr>
              <tr>
                <td>43112249</td>
                <td>71f4e9e2f2971dd282618e884e8e58e9f155747cbc3dde3b9ec0bef7ce177</td>
                <td>2025-03-20 14:31:39</td>
              </tr>
            </table>
          </div>
          <div class="fd-qylfn-right">
            <ul class="fd-qylfn-right-content">
              <li class="fd-qylfn-right-li">
                <div class="fd-qylfn-right-li-name">
                  <img src="../../assets/height.png" alt="">
                </div>
                <div class="fd-qylfn-right-li-sj">
                  <p class="fd-qylfn-right-li-num">43112255</p>
                  <p class="fd-qylfn-right-li-text">区块高度</p>
                </div>
              </li>
              <li class="fd-qylfn-right-li">
                <div class="fd-qylfn-right-li-name">
                  总
                </div>
                <div class="fd-qylfn-right-li-sj">
                  <p class="fd-qylfn-right-li-num">43524185</p>
                  <p class="fd-qylfn-right-li-text">交易总数</p>
                </div>
              </li>
              <!-- <li class="fd-qylfn-right-li">
                  <div class="fd-qylfn-right-li-name">
                      取
                  </div>
                  <div class="fd-qylfn-right-li-sj">
                      <p class="fd-qylfn-right-li-num">5757</p>
                      <p class="fd-qylfn-right-li-dw">次</p>
                      <p class="fd-qylfn-right-li-k"></p>
                      <p class="fd-qylfn-right-li-text">取证总数</p>
                  </div>
              </li> -->
            </ul>
            <a class="fd-qylfn-right-button1" href='https://czt.jiulaw.cn/external-verify'
               target="_blank">存证记录核验</a>
            <a class="fd-qylfn-right-button2" href='https://czt.jiulaw.cn/external-verify'
               target="_blank">文件一致性核验</a>
          </div>
        </div>
      </div>
    </div>
    <common-bottom/>
  </div>
</template>

<script>
import CommonBottom from "../../component/common-bottom.vue";

export default {
  components: {CommonBottom},
  props: {},
  data() {
    return {
      timer: null,
      index: 0,
    };
  },

  mounted() {
    const srcList = [require('@/assets/bg_1.png'), require('@/assets/bg_2.png')]
    this.index = 0
    this.timer = setInterval(() => {
      if (this.index == 0) {
        this.index = 1
      } else {
        this.index = 0
      }
      this.$refs.refBannerDiv.style.backgroundImage = `url(${srcList[this.index]})`
    }, 3000)
  },

  beforeDestroy() {
    clearInterval(this.timer)
  },

  methods: {
    openVerify() {
      window.open('https://czt.jiulaw.cn/external-verify')
    },

    openWindow(pageName) {
      this.$router.push({name: pageName})
    },
  },
};
</script>

<style scoped lang="css">
.page {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.section {
  //padding-bottom: 48.6091vw;
  padding-bottom: 4vw;
  background-color: #e3e9f24d;
}

.section_2 {
  padding-bottom: 16.6903vw;
  background-image: url('../../assets/bg_1.png');
  background-position: 0% 0%;
  background-size: 100vw 41.6669vw;
  background-repeat: no-repeat;
}

.section_3 {
  padding: 0.5857vw 6.1486vw;
  background-color: #fefeff29;
}

.image {
  width: 2.0498vw;
  height: 2.0498vw;
}

.text {
  color: #ffffff;
  font-size: 1.6669vw;
  font-family: 阿里妈妈数黑体;
  font-weight: 700;
}

.text-wrapper {
  background-image: linear-gradient(285deg, #f45a34 -36.1%, #ff9e36 99.4%);
  border-radius: 0.2928vw;
  width: 6.6669vw;
  height: 2.5vw;
}

.text_2 {
  color: #ffffff;
  font-size: 1.0417vw;
  font-family: Microsoft YaHei UI;
}

.text_3 {
  margin-left: 6.1486vw;
  color: #ffffff;
  font-size: 4vw;
  font-family: 微软雅黑;
  font-weight: 700;
  line-height: 7.604vw;
  width: 59.5168vw;
}

.font {
  font-size: 2.8126vw;
  font-family: Microsoft YaHei UI;
  color: #172b4d;
}

.text_4 {
  margin-top: 4.978vw;
}

.equal-division_1 {
  align-self: stretch;
  margin: 5.8565vw 6.1486vw 0;
}

.section_4 {
  flex: 1 1 20.6252vw;
}

.equal-division-item {
  padding-bottom: 1.757vw;
  background-color: #ffffff;
  border-radius: 0.2928vw;
  height: 18.7504vw;
}

.image_2 {
  width: 20.6435vw;
  height: 13.7628vw;
}

.group {
  padding: 0 1.1706vw;
}

.font_2 {
  font-size: 1.1457vw;
  font-family: Microsoft YaHei UI;
  color: #172b4d;
}

.image_3 {
  width: 1.683vw;
  height: 1.683vw;
}

.text_5 {
  margin-top: 4.978vw;
}

.section_5 {
  flex: 1 1 28.0732vw;
}

.equal-division-item_2 {
  background-color: #ffffff;
  height: 9.3748vw;
}

.image_4 {
  height: 9.3748vw;
}

.group_2 {
  margin-right: 2.9283vw;
}

.text-wrapper_1 {
  border-radius: 0.2928vw;
  width: 9.3704vw;
  border: solid 0.05124vw #0147be;
  height: 3.5139vw;
}

.font_1 {
  font-size: 1.6105vw;
  font-family: Microsoft YaHei UI;
  color: #0147be;
}

.group_3 {
  margin-right: 0.8785vw;
}

.image_5 {
  width: 14.0549vw;
  height: 9.3712vw;
}

.view {
  margin-right: 0.2928vw;
  border-radius: 0.2928vw;
  width: 9.3704vw;
  border: solid 0.05124vw #0147be;
  height: 3.5139vw;
}

.text-wrapper_3 {
  margin-top: -43.631vw;
  padding: 4.978vw 0 47.1449vw;
  background-image: url('../../assets/fba0aa1b9d0f2406c1a3eb0c33956aa6.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.text_11 {
  color: #ffffff;
}

.section_bottom_6 {
  padding: 0 0.8785vw;
  background-color: #172b4d;
}

.group_bottom_4 {
  margin-right: 5.2709vw;
  padding: 1.757vw 0;
}

.group_bottom_5 {
  margin-top: 0.2928vw;
  padding: 0 5.2709vw;
}

.font_4 {
  font-size: 1.1457vw;
  font-family: 微软雅黑;
  color: #ffffff;
  cursor: pointer;
}

.font_bottom_4 {
  font-size: 1.1457vw;
  font-family: 微软雅黑;
  color: #ffffff;
  cursor: pointer;
}

.text_12 {
  opacity: 0.65;
}

.text_bottom_12 {
  opacity: 0.65;
}

.section_bottom_7 {
  background-color: #ffffff40;
  width: 0.05198vw;
  height: 5vw;
}

.group_bottom_6 {
  margin-left: 5.2709vw;
  margin-top: 0.2928vw;
}

.text_bottom_15 {
  font-weight: 700;
}

.text_19 {
  margin-left: 3.2211vw;
  margin-top: 3.3675vw;
}

.text_20 {
  margin-left: 3.2211vw;
  margin-top: 3.3675vw;
}

.text_21 {
  margin-left: 3.2211vw;
  margin-top: 3.3675vw;
}

.group_bottom_7 {
  margin: 0 5.2709vw;
  padding: 0.8785vw 0;
  border-top: solid 0.05198vw #ffffff40;
}

.image_bottom_6 {
  width: 2.1962vw;
  height: 1.683vw;
}

.bottom-link-friend {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* 区域链赋能 */
.fd-qylfn {
  width: 100%;
  height: 710px;
  background: url(../../assets/bcgimg.png) no-repeat;
  background-size: 100vw 710px;
  padding-bottom: 40px;
}

.fd-qylfn-content {
  width: 1200px;
  margin: 0 auto;
  padding-top: 48px;
}

.fd-qylfn-title {
  margin-bottom: 38px;
  text-align: center;
  font-size: 32px;
  color: #fff;
}

.fd-qylfn-left {
  float: left;
  width: 884px;
  height: 530px;
}

.fd-qylfn-table {
  width: 100%;
  height: 100%;
  border: 1px solid #4f80b6;
  border-radius: 20px;
  box-sizing: border-box;
  text-align: center;
  font-size: 15px;
  color: #b3cce9;
}

.fd-qylfn-table thead {
  height: 69px;
  box-sizing: border-box;
  font-size: 18px;
  color: #c5defa;
  border: 1px solid #6796ca;
  background-color: rgba(79, 128, 182, 0.25);
}

.fd-qylfn-table tr {
  height: 66px;
  box-sizing: 66px;
  border: 1px solid #4f80b6;
  background-color: rgba(140, 173, 211, 0.1);
}

.fd-qylfn-table tr td {
  vertical-align: middle;
}

.fd-qylfn-table tr td:first-child {
  width: 135px;
}

.fd-qylfn-table tr td:last-child {
  width: 184px;
}

.fd-qylfn-right {
  width: 258px;
  height: 530px;
  float: left;
  //margin-left: 58px;
}

.fd-qylfn-right-content {
  list-style-type: none;
  /* height: 400px; */
  width: 100%;
}

.fd-qylfn-right-li {
  //position: relative;
  width: 100%;
  height: 133px;
  margin-bottom: 3px;
  display: flex;
}

.fd-qylfn-right-li-name {
  float: left;
  width: 48px;
  height: 48px;
  margin-right: 20px;
  border: 1px solid #4c7db3;
  border-radius: 24px;
  font-size: 20px;
  color: #fff;
  text-align: center;
  line-height: 45px;
}

.fd-qylfn-right-li-name img {
  position: relative;
  top: 4px;
}

.fd-qylfn-right-li-sj {
  float: left;
}

.fd-qylfn-right-li-num {
  float: left;
  margin-bottom: 16px;
  margin-top: 0px;
  font-size: 48px;
  font-family: Arial, Helvetica, sans-serif;
  color: #fff;
  letter-spacing: -1px;
}

.fd-qylfn-right-li-dw {
  position: relative;
  float: left;
  font-size: 18px;
  color: #fff;
  vertical-align: bottom;
  left: 54px;
  top: 27px;
}

.fd-qylfn-right-li-k {
  clear: both;
}

.fd-qylfn-right-li-text {
  font-size: 18px;
  color: #fff;
}

.fd-qylfn-right-button1, .fd-qylfn-right-button2 {
  width: 256px;
  height: 54px;
  box-sizing: border-box;
  background-color: rgba(20, 99, 185, 0.5);
  border: 1px solid #5ea0e9;
  border-radius: 5px;
  font-size: 18px;
  color: #50c3ff;
  text-align: center;
  line-height: 54px;
  outline: none;
  cursor: pointer;
  display: block;
  margin-left: 40px;
  text-decoration: none;
}

.fd-qylfn-right-button1:hover, .fd-qylfn-right-button2:hover {
  background-color: rgba(22, 86, 155, 0.5);
  border: 1px solid #8abaee;
  color: #fff;
}

.fd-qylfn-right-button1 {
  margin-bottom: 20px;
  margin-top: 5px;
}
</style>
