import Vue from 'vue';
import VueRouter from 'vue-router';
import Page_qyfxts from '../pages/page_qyfxts/page_qyfxts.vue';
import Page_7zhishichanquanjiandingfuwu from '../pages/Page_7zhishichanquanjiandingfuwu/Page_7zhishichanquanjiandingfuwu.vue';
import Page_sjhg from '../pages/page_sjhg/page_sjhg.vue';
import Page_szkj_zh from '../pages/page_szkj_zh/page_szkj_zh.vue';
import Page_szkj_en from '../pages/page_szkj_en/page_szkj_en.vue';
import Page_tjfwxtdl from '../pages/page_tjfwxtdl/page_tjfwxtdl.vue';
import Page_czqzfw from '../pages/page_czqzfw/page_czqzfw.vue';
import Page_index from '../pages/page_index/page_index.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/page_qyfxts',
    name: 'page_qyfxts',
    component: Page_qyfxts,
  },
  {
    path: '/page_zscqjd',
    name: 'page_zscqjd',
    component: Page_7zhishichanquanjiandingfuwu,
  },
  {
    path: '/page_sjhg',
    name: 'page_sjhg',
    component: Page_sjhg,
  },
  {
    path: '/page_szkj_zh',
    name: 'page_szkj_zh',
    component: Page_szkj_zh,
  },
  {
    path: '/page_szkj_en',
    name: 'page_szkj_en',
    component: Page_szkj_en,
  },
  {
    path: '/page_tjfwxtdl',
    name: 'page_tjfwxtdl',
    component: Page_tjfwxtdl,
  },
  {
    path: '/page_czqzfw',
    name: 'page_czqzfw',
    component: Page_czqzfw,
  },
  {
    path: '/',
    name: 'page_index',
    component: Page_index,
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;
