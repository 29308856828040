<template>
  <div class="flex-col page">
    <div class="flex-col section">
      <div class="flex-row justify-between items-center section_2">
        <div class="flex-row">
          <img class="self-center image" src="../../assets/6f7ef0b09187f296c1dc6a4af0c3d406.png" />
          <span class="self-start text ml-16">数澄保</span>
        </div>
        <div class="flex-col justify-center items-center text-wrapper" style="cursor: pointer" @click="openVerify()"><span class="text_2">证据查验</span></div>
      </div>
      <div class="flex-col group">
        <div class="flex-row items-center self-stretch">
          <a class="font text_3 cursor-point" href="/">首页</a>
          <img class="image_2 ml-4" src="../../assets/5ede9e4ecda5b3f787cbbb15e4ad1974.png" />
          <span class="font text_4 ml-4">知识产权鉴定服务</span>
        </div>
        <span class="self-start font_2 text_5 mt-12">知识产权鉴定服务</span>
      </div>
    </div>
    <div class="flex-col mt-68">
      <div class="flex-col items-start relative group_2">
        <span class="text_6">中国信息通信研究院司法鉴定所</span>
        <span class="font_3 text_7">
          中国信息通信研究院是中华人民共和国最高人民法院诉讼资产网的入库机构，是国家知识产权局“知识产权鉴定机构名录库”和中国知识产权研究会“知识产权鉴定机构名录库”的入库机构。入选北京市高级人民法院等多家法院司法鉴定机构名册。中国信通院的鉴定人在中国知识产权研究会的知识产权鉴定专业委员会中担任副主任委员及秘书长职务。鉴定领境涉及通信、芯片、电路、软件源代码、机械、化学、经营信息等。
        </span>
        <div class="flex-col justify-start group_3 pos">
          <div class="flex-col section_3">
            <div class="flex-col justify-start items-center text-wrapper_2">
              <span class="font_2">知识产权鉴定</span>
            </div>
            <div class="flex-col justify-start relative group_4">
              <div class="flex-row equal-division">
                <div class="flex-col equal-division-item section_4">
                  <div class="flex-row justify-between self-stretch group_5">
                    <span class="self-start font_4 text_8">专利鉴定</span>
                    <img class="self-center image_4" src="../../assets/26ee6364247ea45984f78db825036a32.png" />
                  </div>
                  <div class="self-start group_7 mt-16">
                    <span class="font_7">•</span>
                    <span class="font_3 text_12">涉缓侵权产品与对比专利的异间性鉴定
                    <br /></span>
                    <span class="font_7">•</span>
                    <span class="font_3 text_13">现有技术鉴定
                      <br />
                    </span>
                    <span class="font_7">•</span>
                    <span class="font_3 text_15">技术平实鉴定
                      <br />
                    </span>
                    <span class="font_7">•</span>
                    <span class="font_3 text_17">标准必要专利鉴定</span>
                  </div>
                </div>
                <div class="flex-col equal-division-item section_5 ml-24">
                  <div class="flex-row justify-between group_5">
                    <span class="self-start font_4 text_8">著作权签定</span>
                    <img class="self-center image_4" src="../../assets/19e0a4d5ad0031a631e0243fa77cbb8e.png" />
                  </div>
                  <span class="font_3 text_14 mt-16">
                    涉缓侵权作品与权利人的作品是否相同或实质相似（源代码、视频作品、美术作品以及文字作品）的鉴定
                  </span>
                </div>
                <div class="flex-col equal-division-item_2 section_6 ml-24">
                  <div class="flex-row justify-between">
                    <span class="self-start font_4 text_11">商业秘密鉴定</span>
                    <img class="image_4" src="../../assets/31b20f7efe1c2b10b4fb1fbe149f574f.png" />
                  </div>
                  <div class="flex-row justify-between mt-8">
                    <div class="group_6">
                      <span class="font_6">•</span>
                      <span class="font_5">非公知鉴定</span>
                    </div>
                    <div class="group_6 view">
                      <span class="font_6">•</span>
                      <span class="font_5">同一性鉴定</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex-col section_7 pos_3">
                <div class="flex-row justify-between self-stretch">
                  <span class="self-start font_4 text_16">合同争议技术签定</span>
                  <img class="image_4" src="../../assets/2def5d7d136838bdca31f6eb2ebbc93c.png" />
                </div>
                <span class="self-start font_5 mt-8">软件开发合同产品功能性鉴定</span>
              </div>
            </div>
          </div>
          <div class="flex-col justify-start items-center image-wrapper pos_2">
            <img class="image_3" src="../../assets/960a121bcf7fb1d142482dbf1b50a890.png" />
          </div>
        </div>
      </div>
      <div class="flex-col section_8">
        <span class="self-center font_2">联系我们</span>
        <div class="flex-row justify-evenly self-stretch group_8 mt-84">
          <div class="flex-row items-center group_9">
            <img class="image_5" src="../../assets/8e56667d11c6d3968fe701f9499e47cb.png" />
            <div class="flex-col items-center ml-16">
              <span class="font_5 text_20">联系人</span>
              <span class="font_5 mt-12">吴先生</span>
            </div>
          </div>
          <div class="flex-row items-center group_10">
            <img class="image_6" src="../../assets/62325843fd1be8d07b702f5308cb1cb7.png" />
            <div class="flex-col items-start ml-16">
              <span class="font_5 text_20">联系电话</span>
              <span class="font_5 mt-12">18608909909</span>
            </div>
          </div>
          <div class="flex-row items-center group_11">
            <img class="image_6" src="../../assets/a628ef4bef71099d74df9e50b278bf83.png" />
            <div class="flex-col items-start ml-16">
              <span class="font_5 text_20">电子邮箱</span>
              <span class="font_5 mt-12">wuhuan@thunisoft.com</span>
            </div>
          </div>
        </div>
      </div>
      <common-bottom/>
    </div>
  </div>
</template>

<script>
  import CommonBottom from "@/component/common-bottom.vue";

  export default {
    components: {CommonBottom},
    props: {},
    data() {
      return {};
    },

    methods: {
      openVerify(){
        window.open('https://czt.jiulaw.cn/external-verify')
      },
    },
  };
</script>

<style scoped lang="css">
  .page {
    background-color: #ffffff;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
  .section {
    padding-bottom: 0.8785vw;
    background-image: url('../../assets/7d3494a7115066f8b61203b58ed9c10b.png');
    background-position: 0% 0%;
    background-size: 100vw 19.6903vw;
    background-repeat: no-repeat;
  }
  .section_2 {
    padding: 0.5857vw 6.1486vw;
    background-color: #fefeff29;
  }
  .image {
    width: 2.0498vw;
    height: 2.0498vw;
  }
  .text {
    color: #ffffff;
    font-size: 1.6669vw;
    font-family: 阿里妈妈数黑体;
    font-weight: 700;
  }
  .text-wrapper {
    background-image: linear-gradient(285deg, #f45a34 -36.1%, #ff9e36 99.4%);
    border-radius: 0.2928vw;
    width: 6.6669vw;
    height: 2.5007vw;
  }
  .text_2 {
    color: #ffffff;
    font-size: 1.0417vw;
    font-family: Microsoft YaHei UI;
  }
  .group {
    padding: 4.978vw 6.1486vw;
  }
  .font {
    font-size: 0.8331vw;
    font-family: Microsoft YaHei UI;
  }
  .text_3 {
    color: #ffffff;
  }
  .image_2 {
    width: 1.0249vw;
    height: 1.0249vw;
  }
  .text_4 {
    color: #ffffffa6;
  }
  .font_2 {
    font-size: 2.8126vw;
    font-family: Microsoft YaHei UI;
    color: #172b4d;
  }
  .text_5 {
    color: #ffffff;
    font-weight: 700;
  }
  .group_2 {
    padding: 1.757vw 6.1486vw 44.5095vw;
  }
  .text_6 {
    color: #172b4d;
    font-size: 1.6669vw;
    font-family: Microsoft YaHei UI;
    font-weight: 700;
  }
  .font_3 {
    font-size: 1.1457vw;
    font-family: Microsoft YaHei UI;
    line-height: 2.1874vw;
    color: #172b4d;
  }
  .text_7 {
    margin-top: 2.6354vw;
    text-align: justify;
    width: 43.9239vw;
  }
  .group_3 {
    padding-top: 22.2555vw;
  }
  .pos {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .section_3 {
    padding-bottom: 4.6852vw;
    background-color: #e3e9f24d;
    height: 38.9649vw;
  }
  .text-wrapper_2 {
    padding: 7.9063vw 0 10.5417vw;
    background-image: url('../../assets/ed99d7b097d9f2844eaf510c67069f8d.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .group_4 {
    margin-top: -4.6852vw;
    padding-bottom: 0.5857vw;
  }
  .equal-division {
    margin: 0 6.1486vw;
  }
  .equal-division-item {
    position: relative;
    flex: 1 1 28.0205vw;
  }
  .section_4 {
    padding: 0 1.757vw 2.0498vw;
    background-color: #fefeff;
    border-radius: 0.2928vw;
    box-shadow: 0vw 0.5205vw 2.0835vw #18264c14;
    height: 17.295vw;
  }
  .group_5 {
    padding: 1.1706vw 0;
    border-bottom: solid 0.05198vw #172b4d33;
  }
  .font_4 {
    font-size: 1.1457vw;
    font-family: Microsoft YaHei UI;
    font-weight: 700;
    color: #172b4d;
  }
  .text_8 {
    margin-top: 0.2928vw;
  }
  .image_4 {
    border-radius: 0.2928vw;
    width: 2.9165vw;
    height: 2.918vw;
  }
  .group_7 {
    width: 21.0461vw;
  }
  .font_7 {
    font-size: 1.1457vw;
    font-family: Microsoft YaHei UI;
    line-height: 2.1874vw;
    color: #172b4d66;
  }
  .text_12 {
    text-align: justify;
  }
  .text_13 {
    margin-left: 0.05564vw;
    text-align: justify;
  }
  .text_15 {
    text-align: justify;
  }
  .text_17 {
  }
  .section_5 {
    padding: 0 1.757vw 4.6852vw;
    background-color: #fefeff;
    border-radius: 0.2928vw;
    box-shadow: 0vw 0.5205vw 2.0835vw #18264c14;
    height: 17.295vw;
  }
  .text_14 {
    text-align: justify;
  }
  .equal-division-item_2 {
    flex: 1 1 28.0205vw;
    align-self: flex-start;
  }
  .section_6 {
    padding: 1.1706vw 1.757vw 2.0498vw;
    background-color: #fefeff;
    border-radius: 0.2928vw;
    box-shadow: 0vw 0.5205vw 2.0835vw #18264c14;
    height: 7.8133vw;
  }
  .text_11 {
    margin-top: 0.2928vw;
  }
  .group_6 {
    line-height: 1.1149vw;
    height: 1.1149vw;
  }
  .font_6 {
    font-size: 1.1457vw;
    font-family: Microsoft YaHei UI;
    color: #172b4d66;
  }
  .font_5 {
    font-size: 1.1457vw;
    font-family: Microsoft YaHei UI;
    color: #172b4d;
  }
  .view {
    margin-right: 3.5132vw;
  }
  .section_7 {
    padding: 1.1706vw 1.757vw 2.0498vw;
    background-color: #fefeff;
    border-radius: 0.2928vw;
    box-shadow: 0vw 0.5205vw 2.0835vw #18264c14;
    width: 27.6732vw;
  }
  .pos_3 {
    position: absolute;
    right: 6.2504vw;
    top: 9.481vw;
  }
  .text_16 {
    margin-top: 0.2928vw;
  }
  .image-wrapper {
    padding: 0.2928vw 0;
    background-color: #fffefe;
    border-radius: 0.2928vw;
    box-shadow: 0vw 0.5205vw 2.0835vw #18264c14;
    width: 38.6457vw;
  }
  .pos_2 {
    position: absolute;
    right: 6.2504vw;
    top: 0;
  }
  .image_3 {
    width: 37.9209vw;
    height: 24.7438vw;
  }
  .section_8 {
    padding: 4.978vw 10.5417vw 4.978vw 15.8126vw;
    background-image: url('../../assets/1838f4159df6001af10d5facdbf78e26.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .group_8 {
    padding-left: 5.2709vw;
  }
  .group_9 {
    padding-bottom: 0.2928vw;
    width: 17.9956vw;
    border-right: solid 0.05198vw #172b4d33;
  }
  .image_5 {
    margin-left: 4.978vw;
    width: 3.3675vw;
    height: 3.3675vw;
  }
  .group_10 {
    padding: 0 4.978vw;
    border-right: solid 0.05198vw #172b4d33;
  }
  .image_6 {
    border-radius: 50%;
    width: 3.3331vw;
    height: 3.3331vw;
  }
  .group_11 {
    margin-bottom: 0.2928vw;
    padding: 0 4.978vw;
  }
  .section_9 {
    padding: 0 0.8785vw;
    background-color: #172b4d;
  }
  .group_12 {
    margin-right: 5.2709vw;
    padding: 1.757vw 0;
  }
  .group_13 {
    margin-top: 0.2928vw;
    padding: 0 5.2709vw;
  }
  .font_8 {
    font-size: 1.1457vw;
    font-family: 微软雅黑;
    color: #ffffff;
  }
  .text_20 {
    opacity: 0.65;
  }
  .section_10 {
    background-color: #ffffff40;
    width: 0.05198vw;
    height: 5.0007vw;
  }
  .group_14 {
    margin-left: 5.2709vw;
    margin-top: 0.2928vw;
  }
  .text_28 {
    font-weight: 700;
  }
  .text_31 {
    margin-left: 3.2211vw;
    margin-top: 2.9283vw;
  }
  .text_32 {
    margin-left: 3.2211vw;
    margin-top: 2.9283vw;
  }
  .text_33 {
    margin-left: 3.2211vw;
    margin-top: 2.9283vw;
  }
  .group_15 {
    margin: 0 5.2709vw;
    padding: 0.8785vw 0;
    border-top: solid 0.05198vw #ffffff40;
  }
  .image_7 {
    width: 2.1962vw;
    height: 1.683vw;
  }
</style>
