var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-col page"},[_c('div',{staticClass:"flex-col self-stretch"},[_c('div',{staticClass:"flex-col section"},[_c('div',{staticClass:"flex-row justify-between items-center section_2"},[_vm._m(0),_c('div',{staticClass:"flex-col justify-center items-center text-wrapper",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openVerify()}}},[_c('span',{staticClass:"text_2"},[_vm._v("证据查验")])])]),_vm._m(1)]),_vm._m(2)]),_vm._m(3),_c('div',{staticClass:"flex-col self-stretch group_3"},[_vm._m(4),_c('div',{staticClass:"flex-col mt-68"},[_vm._m(5),_c('common-bottom')],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-row"},[_c('img',{staticClass:"self-center image",attrs:{"src":require("../../assets/6f7ef0b09187f296c1dc6a4af0c3d406.png")}}),_c('span',{staticClass:"self-start text ml-18"},[_vm._v("数澄保")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-col group"},[_c('div',{staticClass:"flex-row items-center self-stretch"},[_c('a',{staticClass:"font text_3 cursor-point",attrs:{"href":"/"}},[_vm._v("首页")]),_c('img',{staticClass:"image_2 ml-3",attrs:{"src":require("../../assets/5ede9e4ecda5b3f787cbbb15e4ad1974.png")}}),_c('span',{staticClass:"font text_4 ml-3"},[_vm._v("数据合规及质量评价")])]),_c('span',{staticClass:"self-start font_2 text_5 mt-14"},[_vm._v("数据合规及质量评价")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-col group_2 mt-68"},[_c('span',{staticClass:"self-center font_2"},[_vm._v("什么是数据合规？")]),_c('span',{staticClass:"self-stretch font_4 text_6 mt-75"},[_vm._v(" 数据合规是指组织或个人在数据全生命周期内对数据进行处理和管理时，必须遵守数据相关法律法规、标准和行业准则等规定，履行法律法规等规定的义务并承担相关责任，确保数据在安全可控、保护个人隐私和知识产权的前提下被依法合规使用。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-col justify-start self-start section_3"},[_c('div',{staticClass:"flex-col items-center section_4"},[_c('span',{staticClass:"font_2"},[_vm._v("企业数据合规面临的挑战")]),_c('img',{staticClass:"image_3 mt-93",attrs:{"src":require("../../assets/f456428b552376a43c2560ce33300df7.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-col group_1"},[_c('span',{staticClass:"self-center font_2"},[_vm._v("律所数据合规服务内容")]),_c('div',{staticClass:"self-stretch grid mt-82"},[_c('div',{staticClass:"flex-col items-start grid-item"},[_c('span',{staticClass:"font_5"},[_vm._v("01")]),_c('span',{staticClass:"font_5 mt-18"},[_vm._v("数据资产盘点分析服务")])]),_c('div',{staticClass:"flex-col items-start grid-item_2"},[_c('span',{staticClass:"font_5"},[_vm._v("02")]),_c('span',{staticClass:"font_5 mt-18"},[_vm._v("数据权属评估服务")])]),_c('div',{staticClass:"flex-col items-start grid-item_3"},[_c('span',{staticClass:"font_5"},[_vm._v("03")]),_c('span',{staticClass:"font_5 mt-18"},[_vm._v("数据合规法律文书撰写服务")])]),_c('div',{staticClass:"flex-col items-start grid-item_4"},[_c('span',{staticClass:"font_5"},[_vm._v("04")]),_c('span',{staticClass:"font_5 mt-18"},[_vm._v("数据生命周期合规评估服务")])]),_c('div',{staticClass:"flex-col items-start grid-item_5"},[_c('span',{staticClass:"font_5"},[_vm._v("05")]),_c('span',{staticClass:"font_5 mt-18"},[_vm._v("数据资产登记服务（协助）")])]),_c('div',{staticClass:"flex-col items-start grid-item_6"},[_c('span',{staticClass:"font_5"},[_vm._v("06")]),_c('span',{staticClass:"font_5 mt-18"},[_vm._v("数据质量评价服务")])]),_c('div',{staticClass:"flex-col items-start grid-item_7"},[_c('span',{staticClass:"font_5"},[_vm._v("07")]),_c('span',{staticClass:"font_5 mt-18"},[_vm._v("商业经营方案合规咨询")])]),_c('div',{staticClass:"flex-col items-start grid-item_8"},[_c('span',{staticClass:"font_5"},[_vm._v("08")]),_c('span',{staticClass:"font_5 mt-18"},[_vm._v("出具法律意见书")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-col section_5"},[_c('span',{staticClass:"self-center font_2"},[_vm._v("联系我们")]),_c('div',{staticClass:"flex-row justify-evenly self-stretch group_4 mt-83"},[_c('div',{staticClass:"flex-row items-center group_5"},[_c('img',{staticClass:"image_4",attrs:{"src":require("../../assets/712394b5280db853277a593f32bbf144.png")}}),_c('div',{staticClass:"flex-col items-center ml-16"},[_c('span',{staticClass:"font_4 text_10"},[_vm._v("联系人")]),_c('span',{staticClass:"font_4 mt-12"},[_vm._v("吴先生")])])]),_c('div',{staticClass:"flex-row items-center group_6"},[_c('img',{staticClass:"image_5",attrs:{"src":require("../../assets/6866af0348e3a420d64708efc0b88bcf.png")}}),_c('div',{staticClass:"flex-col items-start ml-17"},[_c('span',{staticClass:"font_4 text_10"},[_vm._v("联系电话")]),_c('span',{staticClass:"font_4 mt-12"},[_vm._v("18608909909")])])]),_c('div',{staticClass:"flex-row items-center group_7"},[_c('img',{staticClass:"image_5",attrs:{"src":require("../../assets/a628ef4bef71099d74df9e50b278bf83.png")}}),_c('div',{staticClass:"flex-col items-start ml-17"},[_c('span',{staticClass:"font_4 text_10"},[_vm._v("电子邮箱")]),_c('span',{staticClass:"font_4 mt-12"},[_vm._v("wuhuan@thunisoft.com")])])])])])
}]

export { render, staticRenderFns }