<template>
  <div class="flex-col page">
    <div class="flex-col section">
      <div class="flex-row justify-between items-center section_2">
        <div class="flex-row">
          <img class="self-center image" src="../../assets/6f7ef0b09187f296c1dc6a4af0c3d406.png"/>
          <span class="self-start text ml-18">数澄保</span>
        </div>
        <div class="flex-col justify-center items-center text-wrapper" style="cursor: pointer" @click="openVerify()"><span class="text_2">证据查验</span></div>
      </div>
      <div class="flex-col group">
        <div class="flex-row items-center self-stretch">
          <a class="font text_3 cursor-point" href="/">首页</a>
          <img class="image_2 ml-3" src="../../assets/cb03801bd1ee1f59153dcb0b010ba28a.png"/>
          <span class="font text_4 ml-3">调解服务</span>
        </div>
        <span class="self-start font_2 text_5 mt-14">调解服务</span>
      </div>
    </div>
    <div class="flex-row justify-center group_2">
      <div class="flex-col justify-start self-center image-wrapper">
        <img class="image_3" src="../../assets/a3290cafff5bb89133fa0eab4c41d3c6.png"/>
      </div>
      <div class="flex-col self-start group_3 ml-81">
        <div class="flex-col items-start">
          <span class="font_3 text_6">线上解纷平台——让维权全程无忧</span>
          <span class="font_2 text_7 mt-17">调解系统</span>
        </div>
        <div class="flex-col items-start mt-58">
          <span class="font_3 text_8">联合国家级权威机构，基于区块链技术，提供知识产权维权服务的管理平台</span>
          <div class="flex-col justify-start items-center text-wrapper_2 mt-28" style="cursor: pointer" @click="openTj()">
            <span class="font_3 text_9">登录系统</span>
          </div>
        </div>
      </div>
    </div>
    <common-bottom/>
  </div>
</template>

<script>
import CommonBottom from "@/component/common-bottom.vue";

export default {
  components: {CommonBottom},
  props: {},
  data() {
    return {};
  },

  methods: {
    openTj() {
      window.open('https://czt.jiulaw.cn/dispute')
    },
    openVerify() {
      window.open('https://czt.jiulaw.cn/external-verify')
    },
  },
};
</script>

<style scoped lang="css">
.ml-3 {
  margin-left: 0.2196vw;
}

.ml-81 {
  margin-left: 5.9297vw;
}

.mt-17 {
  margin-top: 1.2445vw;
}

.ml-21 {
  margin-left: 1.5373vw;
}

.mt-23 {
  margin-top: 1.6837vw;
}

.page {
  background-color: #ffffff;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.section {
  padding-bottom: 1vw;
  background-image: url('../../assets/190b66c13a30031b21956c9a787dc142.png');
  background-position: 0% 0%;
  background-size: 100vw 19.6845vw;
  background-repeat: no-repeat;
}

.section_2 {
  padding: 0.4736vw 6.2496vw 0.6245vw 6.2504vw;
  background-color: #fefeff29;
}

.image {
  width: 2.0498vw;
  height: 2.0498vw;
}

.text {
  color: #ffffff;
  font-size: 1.6669vw;
  font-family: 阿里妈妈数黑体;
  font-weight: 700;
}

.text-wrapper {
  background-image: linear-gradient(285deg, #f45a34 -36.1%, #ff9e36 99.4%);
  border-radius: 0.4165vw;
  width: 6.6669vw;
  height: 2.5vw;
}

.text_2 {
  color: #ffffff;
  font-size: 1.0417vw;
  font-family: Microsoft YaHei UI;
}

.group {
  padding: 4.9993vw 6.2496vw 4.9985vw 6.2504vw;
}

.font {
  font-size: 0.8331vw;
  font-family: Microsoft YaHei UI;
}

.text_3 {
  color: #ffffff;
}

.image_2 {
  width: 1.0249vw;
  height: 1.0249vw;
}

.text_4 {
  color: #ffffffa6;
}

.font_2 {
  font-size: 2.8126vw;
  font-family: Microsoft YaHei UI;
}

.text_5 {
  color: #ffffff;
  font-weight: 700;
}

.group_2 {
  padding: 6.2496vw 0;
}

.image-wrapper {
  padding: 0.9722vw 0 0.8111vw;
  background-image: url('../../assets/cc5ffdc4119a0c11c0ad7b0d82e3a62c.png');
  background-position: 0% 0%;
  background-size: 43.7504vw 29.1625vw;
  background-repeat: no-repeat;
  width: 43.7504vw;
  height: 29.1625vw;
}

.image_3 {
  margin-left: 2.4114vw;
  margin-right: 2.9795vw;
  width: 38.3602vw;
  height: 27.3792vw;
}

.group_3 {
  margin-top: 6.2489vw;
}

.font_3 {
  font-size: 1.1457vw;
  font-family: Microsoft YaHei UI;
  font-weight: 700;
}

.text_6 {
  color: #0147be;
}

.text_7 {
  color: #172b4d;
}

.text_8 {
  color: #172b4d;
  font-weight: unset;
}

.text-wrapper_2 {
  padding: 0.6772vw 1.1237vw;
  background-color: #0147be;
  border-radius: 0.3126vw;
  width: 10.4165vw;
}

.text_9 {
  color: #ffffff;
}

.section_3 {
  margin-top: 0.7284vw;
  padding: 0 0.9195vw;
  background-color: #172b4d;
}

.group_4 {
  margin-right: 5.2013vw;
  padding: 1.6662vw 0 1.6669vw;
}

.group_5 {
  margin-top: 0.2086vw;
  padding: 0 5.3302vw;
}

.font_4 {
  font-size: 1.1457vw;
  font-family: 微软雅黑;
  color: #ffffff;
}

.text_10 {
  opacity: 0.65;
}

.section_4 {
  background-color: #ffffff40;
  width: 0.05198vw;
  height: 4.9993vw;
}

.group_6 {
  margin-left: 5.4165vw;
  margin-top: 0.2086vw;
}

.text_12 {
  font-weight: 700;
}

.text_16 {
  margin-left: 3.3309vw;
  margin-top: 3.6603vw;
}

.text_17 {
  margin-left: 3.3309vw;
  margin-top: 3.6603vw;
}

.text_18 {
  margin-left: 3.3309vw;
  margin-top: 3.6603vw;
}

.group_7 {
  margin-left: 5.3309vw;
  margin-right: 5.1999vw;
  padding: 0.8331vw 0 0.7643vw;
  border-top: solid 0.05198vw #ffffff40;
}

.image_4 {
  width: 2.1962vw;
  height: 1.6837vw;
}
</style>
