<template>
  <div class="flex-col section_bottom_6">
    <div class="flex-row group_bottom_4">
      <div class="flex-col self-start group_bottom_5">
        <div class="flex-row">
          <span class="font_bottom_4 text_bottom_12">发起单位</span>
          <span class="font_bottom_4 ml-20">海口国际商事调解中心</span>
        </div>
        <div class="flex-row mt-24">
          <span class="font_bottom_4 text_bottom_12">技术支持</span>
          <span class="font_bottom_4 ml-20"
                @click="openUrl('https://jiulaw.cn/#/index')">北京华宇九品科技有限公司</span>
        </div>
      </div>
      <div class="self-center section_bottom_7"></div>
      <div class="flex-col items-start self-start group_bottom_6">
        <span class="font_bottom_4 text_bottom_15">友情链接</span>
        <div class="bottom-link-friend mt-24">
          <span class="font_bottom_4" @click="openUrl('https://www.rschn.com/zh/sindex')">海南生态软件园</span>
          <span class="font_bottom_4 ml-20"
                @click="openUrl('https://www.hicourt.gov.cn/index.shtml')">海南法院-天涯法律网</span>
        </div>
      </div>
      <!--        <span class="self-start font_4 text_19">海口国际商事调解中心</span>-->
      <!--        <span class="self-start font_4 text_21">海南电子诉讼平台</span>-->
    </div>
    <div class="flex-row justify-center group_bottom_7">
      <img class="self-center image_bottom_6" src="../assets/d2b3de442d858dfe52e8e75906ac341e.png"/>
      <span class="self-start font_bottom_4 text_bottom_12 ml-4" @click="openUrl('https://beian.miit.gov.cn/')">琼ICP备2025050880号</span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'common-bottom',
  components: {},
  data() {
    return {msg: 'hello'}
  },
  methods: {
    openUrl(url) {
      window.open(url)
    },
  },

}
</script>

<style scoped lang="css">
.section_bottom_6 {
  padding: 0 0.8785vw;
  background-color: #172b4d;
}

.text_bottom_12 {
  opacity: 0.65;
}

.section_bottom_7 {
  background-color: #ffffff40;
  width: 0.05198vw;
  height: 5vw;
}

.group_bottom_5 {
  margin-top: 0.2928vw;
  padding: 0 5.2709vw;
}

.group_bottom_4 {
  margin-right: 5.2709vw;
  padding: 1.757vw 0;
}

.group_bottom_5 {
  margin-top: 0.2928vw;
  padding: 0 5.2709vw;
}

.group_bottom_6 {
  margin-left: 5.2709vw;
  margin-top: 0.2928vw;
}

.text_bottom_15 {
  font-weight: 700;
}

.image_bottom_6 {
  width: 2.1962vw;
  height: 1.683vw;
}

.font_bottom_4 {
  font-size: 1.1457vw;
  font-family: 微软雅黑;
  color: #ffffff;
  cursor: pointer;
}

.group_bottom_7 {
  margin: 0 5.2709vw;
  padding: 0.8785vw 0;
  border-top: solid 0.05198vw #ffffff40;
}
</style>
