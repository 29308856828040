<template>
  <div class="flex-col page">
    <div class="flex-col self-stretch">
      <div class="flex-col section">
        <div class="flex-row justify-between items-center section_2">
          <div class="flex-row">
            <img class="self-center image" src="../../assets/6f7ef0b09187f296c1dc6a4af0c3d406.png" />
            <span class="self-start text ml-18">数澄保</span>
          </div>
          <div class="flex-col justify-center items-center text-wrapper" style="cursor: pointer" @click="openVerify()"><span class="text_2">证据查验</span></div>
        </div>
        <div class="flex-col group">
          <div class="flex-row items-center self-stretch">
            <a class="font text_3 cursor-point" href="/">首页</a>
            <img class="image_2 ml-3" src="../../assets/5ede9e4ecda5b3f787cbbb15e4ad1974.png" />
            <span class="font text_4 ml-3">数据合规及质量评价</span>
          </div>
          <span class="self-start font_2 text_5 mt-14">数据合规及质量评价</span>
        </div>
      </div>
      <div class="flex-col group_2 mt-68">
        <span class="self-center font_2">什么是数据合规？</span>
        <span class="self-stretch font_4 text_6 mt-75">
          数据合规是指组织或个人在数据全生命周期内对数据进行处理和管理时，必须遵守数据相关法律法规、标准和行业准则等规定，履行法律法规等规定的义务并承担相关责任，确保数据在安全可控、保护个人隐私和知识产权的前提下被依法合规使用。
        </span>
      </div>
    </div>
    <div class="flex-col justify-start self-start section_3">
      <div class="flex-col items-center section_4">
        <span class="font_2">企业数据合规面临的挑战</span>
        <img class="image_3 mt-93" src="../../assets/f456428b552376a43c2560ce33300df7.png" />
      </div>
    </div>
    <div class="flex-col self-stretch group_3">
      <div class="flex-col group_1">
        <span class="self-center font_2">律所数据合规服务内容</span>
        <div class="self-stretch grid mt-82">
          <div class="flex-col items-start grid-item">
            <span class="font_5">01</span>
            <span class="font_5 mt-18">数据资产盘点分析服务</span>
          </div>
          <div class="flex-col items-start grid-item_2">
            <span class="font_5">02</span>
            <span class="font_5 mt-18">数据权属评估服务</span>
          </div>
          <div class="flex-col items-start grid-item_3">
            <span class="font_5">03</span>
            <span class="font_5 mt-18">数据合规法律文书撰写服务</span>
          </div>
          <div class="flex-col items-start grid-item_4">
            <span class="font_5">04</span>
            <span class="font_5 mt-18">数据生命周期合规评估服务</span>
          </div>
          <div class="flex-col items-start grid-item_5">
            <span class="font_5">05</span>
            <span class="font_5 mt-18">数据资产登记服务（协助）</span>
          </div>
          <div class="flex-col items-start grid-item_6">
            <span class="font_5">06</span>
            <span class="font_5 mt-18">数据质量评价服务</span>
          </div>
          <div class="flex-col items-start grid-item_7">
            <span class="font_5">07</span>
            <span class="font_5 mt-18">商业经营方案合规咨询</span>
          </div>
          <div class="flex-col items-start grid-item_8">
            <span class="font_5">08</span>
            <span class="font_5 mt-18">出具法律意见书</span>
          </div>
        </div>
      </div>
      <div class="flex-col mt-68">
        <div class="flex-col section_5">
          <span class="self-center font_2">联系我们</span>
          <div class="flex-row justify-evenly self-stretch group_4 mt-83">
            <div class="flex-row items-center group_5">
              <img class="image_4" src="../../assets/712394b5280db853277a593f32bbf144.png" />
              <div class="flex-col items-center ml-16">
                <span class="font_4 text_10">联系人</span>
                <span class="font_4 mt-12">吴先生</span>
              </div>
            </div>
            <div class="flex-row items-center group_6">
              <img class="image_5" src="../../assets/6866af0348e3a420d64708efc0b88bcf.png" />
              <div class="flex-col items-start ml-17">
                <span class="font_4 text_10">联系电话</span>
                <span class="font_4 mt-12">18608909909</span>
              </div>
            </div>
            <div class="flex-row items-center group_7">
              <img class="image_5" src="../../assets/a628ef4bef71099d74df9e50b278bf83.png" />
              <div class="flex-col items-start ml-17">
                <span class="font_4 text_10">电子邮箱</span>
                <span class="font_4 mt-12">wuhuan@thunisoft.com</span>
              </div>
            </div>
          </div>
        </div>
        <common-bottom/>
      </div>
    </div>
  </div>
</template>

<script>
  import CommonBottom from "@/component/common-bottom.vue";

  export default {
    components: {CommonBottom},
    props: {},
    data() {
      return {};
    },

    methods: {
      openVerify(){
        window.open('https://czt.jiulaw.cn/external-verify')
      },
    },
  };
</script>

<style scoped lang="css">
  .ml-3 {
    margin-left: 0.2196vw;
  }
  .mt-75 {
    margin-top: 5.4905vw;
  }
  .mt-93 {
    margin-top: 6.8082vw;
  }
  .mt-83 {
    margin-top: 6.0761vw;
  }
  .ml-17 {
    margin-left: 1.2445vw;
  }
  .ml-21 {
    margin-left: 1.5373vw;
  }
  .mt-23 {
    margin-top: 1.6837vw;
  }
  .page {
    background-color: #ffffff;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
  .section {
    padding-bottom: 0.9949vw;
    background-image: url('../../assets/a91e686fd112453dac7f7f3e88faed38.png');
    background-position: 0% 0%;
    background-size: 100vw 19.6852vw;
    background-repeat: no-repeat;
  }
  .section_2 {
    padding: 0.4736vw 6.2496vw 0.6245vw 6.2504vw;
    background-color: #fefeff29;
  }
  .image {
    width: 2.0498vw;
    height: 2.0498vw;
  }
  .text {
    color: #ffffff;
    font-size: 1.6669vw;
    font-family: 阿里妈妈数黑体;
    font-weight: 700;
  }
  .text-wrapper {
    background-image: linear-gradient(285deg, #f45a34 -36.1%, #ff9e36 99.4%);
    border-radius: 0.4165vw;
    width: 6.6669vw;
    height: 2.5vw;
  }
  .text_2 {
    color: #ffffff;
    font-size: 1.0417vw;
    font-family: Microsoft YaHei UI;
  }
  .group {
    padding: 4.9993vw 6.2496vw 4.9993vw 6.2504vw;
  }
  .font {
    font-size: 0.8331vw;
    font-family: Microsoft YaHei UI;
  }
  .text_3 {
    color: #ffffff;
  }
  .image_2 {
    width: 1.0249vw;
    height: 1.0249vw;
  }
  .text_4 {
    color: #ffffffa6;
  }
  .font_2 {
    font-size: 2.8126vw;
    font-family: Microsoft YaHei UI;
    color: #172b4d;
  }
  .text_5 {
    color: #ffffff;
    font-weight: 700;
  }
  .group_2 {
    padding: 0 6.2504vw;
  }
  .font_4 {
    font-size: 1.1457vw;
    font-family: Microsoft YaHei UI;
    color: #172b4d;
  }
  .text_6 {
    line-height: 2.1874vw;
    text-align: justify;
  }
  .section_3 {
    margin-left: -0.6772vw;
    margin-top: 5.5659vw;
    background-image: url('../../assets/4782d4e4763853cc2783833c487716b5.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 101.3909vw;
  }
  .section_4 {
    margin-left: 0.6772vw;
    margin-right: 0.7138vw;
    padding: 4.9993vw 0 5.8433vw;
    background-image: url('../../assets/4c57217331f1ac76a2e054384e44626b.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100vw;
  }
  .image_3 {
    width: 72.328vw;
    height: 28.7701vw;
  }
  .group_3 {
    margin-top: 5.0168vw;
  }
  .group_1 {
    padding-left: 6.2504vw;
    padding-right: 6.2306vw;
  }
  .grid {
    height: 15.4217vw;
    display: grid;
    grid-template-rows: repeat(2, minmax(0, 1fr));
    grid-template-columns: repeat(4, minmax(0, 1fr));
    row-gap: 1.7628vw;
    column-gap: 1.7518vw;
  }
  .grid-item {
    padding: 1.2496vw 1.6662vw 2.3133vw;
    background-image: url('../../assets/15a1a68808c954a396767c21f70b30a6.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .font_5 {
    font-size: 1.1457vw;
    font-family: Microsoft YaHei UI;
    color: #ffffff;
  }
  .grid-item_2 {
    padding: 1.2496vw 1.6669vw 2.3133vw;
    background-image: url('../../assets/c13306d02aedc77cbef2949d052ac6ae.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .grid-item_3 {
    padding: 1.2496vw 1.6669vw 2.3133vw;
    background-image: url('../../assets/b8a76b867a62c77ecb778f4bffd05307.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .grid-item_4 {
    padding: 1.2496vw 1.6662vw 2.3133vw;
    background-image: url('../../assets/96ca6616ee9fbc954842566c801b3f23.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .grid-item_5 {
    padding: 1.2504vw 1.6662vw 2.2826vw;
    background-image: url('../../assets/1cb1359f2761eef6b225f7b0cb0c5401.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .grid-item_6 {
    padding: 1.2504vw 1.6669vw 2.3133vw;
    background-image: url('../../assets/728212205612fb87f95914150376f47a.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .grid-item_7 {
    padding: 1.2504vw 1.6669vw 2.3243vw;
    background-image: url('../../assets/3c77668f328978050d244df0fa70f1ec.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .grid-item_8 {
    padding: 1.2504vw 1.6662vw 2.3221vw;
    background-image: url('../../assets/1d864813b4da8411a5585603eac8bb3b.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .section_5 {
    padding: 4.9993vw 10.6852vw 5.0293vw 15.6852vw;
    background-image: url('../../assets/cf6c67c4c71ecd183088681df37bf4b8.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .group_4 {
    padding-left: 5.3287vw;
  }
  .group_5 {
    padding-bottom: 0.1735vw;
    width: 17.9963vw;
    border-right: solid 0.05198vw #172b4d33;
  }
  .image_4 {
    margin-left: 4.9239vw;
    width: 3.3675vw;
    height: 3.3675vw;
  }
  .group_6 {
    padding: 0 4.7826vw 0.2079vw 5vw;
    border-right: solid 0.05198vw #172b4d33;
  }
  .image_5 {
    border-radius: 50%;
    width: 3.3331vw;
    height: 3.3331vw;
  }
  .group_7 {
    margin-bottom: 0.2079vw;
    padding: 0 5vw;
  }
  .section_6 {
    padding: 0 0.9195vw;
    background-color: #172b4d;
  }
  .group_8 {
    margin-right: 5.2013vw;
    padding: 1.6662vw 0 1.6669vw;
  }
  .group_9 {
    margin-top: 0.2086vw;
    padding: 0 5.3302vw;
  }
  .font_6 {
    font-size: 1.1457vw;
    font-family: 微软雅黑;
    color: #ffffff;
  }
  .text_10 {
    opacity: 0.65;
  }
  .section_7 {
    background-color: #ffffff40;
    width: 0.05198vw;
    height: 4.9993vw;
  }
  .group_10 {
    margin-left: 5.4165vw;
    margin-top: 0.2086vw;
  }
  .text_19 {
    font-weight: 700;
  }
  .text_23 {
    margin-left: 3.3258vw;
    margin-top: 3.0205vw;
  }
  .text_24 {
    margin-left: 3.3294vw;
    margin-top: 3.0205vw;
  }
  .text_25 {
    margin-left: 3.3104vw;
    margin-top: 3.0205vw;
  }
  .group_11 {
    margin-left: 5.3309vw;
    margin-right: 5.1999vw;
    padding: 0.8331vw 0 0.7643vw;
    border-top: solid 0.05198vw #ffffff40;
  }
  .image_6 {
    width: 2.1962vw;
    height: 1.6837vw;
  }
</style>
